import { useState } from "react";
import { FaRegEye, FaRegEyeSlash } from "react-icons/fa";

const TogglePassword = () => {
    const [type, setType] = useState("password");
    const [icon, setIcon] = useState(<FaRegEye />);
    const toggle = () => {
        setIcon(type === "text" ? <FaRegEye /> : <FaRegEyeSlash />);
        setType(type === "password" ? "text" : "password");
    }
    return { type, icon, toggle };
}

export { TogglePassword };