import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { MyContext } from '../../../App';
import { ImageHost } from '../../../app.config';
import { productNameById } from '../../../Utils/utils';


const Products = ({ products }) => {

    const { uiText } = useContext(MyContext);
    const navigate = useNavigate();

    return (
        <div id='products' className='px-8 pb-20 pt-10 max-w-[1200px] mx-auto'>
            <div className='text-2xl md:text-3xl font-medium'>
                {uiText.home.topProductsToday}
            </div>
            <div className="products flex flex-wrap gap-10 mt-10 justify-center md:justify-start">
                {products.map((product, i) => <div key={i} className='product rounded-lg bg-gray-200 max-w-[100%] md:max-w-[300px] shadow-lg' onClick={() => navigate(`/new/${product.id}`)}>
                    <img src={`${ImageHost}/1xbet.jpg`} alt="" className='rounded-lg rb-0 max-w-[100%]' />
                    <h2 className="text-xl font-medium mt-6 mb-2 px-3">{productNameById(product.id)}</h2>
                    <p className="mb-6 text-sm px-3 text-gray-500">{product.description}</p>

                    <button className='btn btn-success w-full rt-0'>Order Now</button>
                </div>)}
            </div>
        </div>
    );
};

export default Products;