import React, { useContext } from 'react';
import { useForm } from 'react-hook-form';
import { Link, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { handleErrors, MyAxios, time } from '../../../Utils/utils';
import { MyContext } from '../../../App';
import { inputLengths } from '../../../app.config';

const ResetPassword = () => {
    document.title = 'Password Recovery || BetShop';
    const { register, formState: { errors }, handleSubmit } = useForm();
    const { setLoading, uiText, setUser, recaptchaRef } = useContext(MyContext);
    const navigate = useNavigate();

    /* handle form submission */
    const onSubmit = async ({ email }) => {
        try {
            setLoading(true);
            const captcha = await recaptchaRef.current.executeAsync();
            const r = await MyAxios.post('/recover', {}, { captcha, email });
            handleErrors(r, true, setUser);
            if (r?.id) {
                localStorage.setItem("recovery", JSON.stringify({ email, time: time() + 60 }));
                navigate(`/recovery/${r.id}`);
            }
        }
        catch (e) { toast.error(e.message); }
        finally { setLoading(false); }
    }


    return (
        <div className='w-[500px] max-w-[90%] bg-white register-box mx-auto my-10 rounded-lg flex flex-col items-center py-10 px-5'>

            <p className="text-2xl font-medium">{uiText.recovery.recovery}</p>
            <small className='text-center'>{uiText.recovery.recoveryBody}</small>

            <form onSubmit={handleSubmit(onSubmit)} className='mt-10 mb-2 w-[400px] max-w-[100%]'>

                {/* email */}
                <div className="form-control">
                    <label className="label pb-0 pl-0"><span className="label-text">{uiText.formFields.email}</span> </label>
                    <input type="email" className="topup-box focus:border-lime-500" {...register("email", {
                        required: uiText.formErrors.email.required,
                        minLength: { value: inputLengths.emailMin, message: uiText.formErrors.email.minLength },
                        maxLength: { value: inputLengths.emailMax, message: uiText.formErrors.email.maxLength },
                    })} />
                    <label className="label pl-1 pt-1"><span className="label-text-alt text-red-400">{errors.email?.message}</span></label>
                </div>

                <div className="flex justify-center mt-5">
                    <input type="submit" className='btn btn-success mx-auto w-[200px] text-white' value={uiText.formFields.submit} />
                </div>

                <div className='flex flex-col items-center mt-5'>
                    <div>{uiText.recovery.goBack} &nbsp;<Link to='/login' className='text-blue-500 underline'>{uiText.formFields.login}</Link></div>
                </div>
            </form>

        </div>
    );
};

export default ResetPassword;