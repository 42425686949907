import React, { useContext, useEffect, useState } from 'react';
import { IoRefresh } from 'react-icons/io5';
import { toast } from 'react-toastify';
import { MyContext } from '../../App';
import { handleErrors, MyAxios } from '../../Utils/utils';

const AdminSettings = () => {
    document.title = 'Settings || BetShop';
    const { user, setUser, setLoading } = useContext(MyContext);
    const [refresh, setRefresh] = useState(0);
    const [online, setOnline] = useState(false);
    const [users, setUsers] = useState(0);

    useEffect(() => {
        (async () => {
            try {
                setLoading(true);
                const r = await MyAxios.get('/admin', { jwt: user.jwt });
                handleErrors(r, true, setUser);
                if (typeof r?.admin?.online !== 'undefined') setOnline(!!r.admin.online);
                setUsers(r?.admin?.users || 0);
            }
            finally { setLoading(false) }
        })();
    }, [setLoading, user, setUser, refresh]);

    const UpdateOnline = async admin => {
        try {
            setLoading(true);
            const r = await MyAxios.patch('/admin', { jwt: user.jwt }, { admin });
            handleErrors(r, true, setUser);
            if (typeof r?.admin?.online !== 'undefined') {
                toast.success(`Admin now ${r.admin.online ? 'Online' : 'Offline'}`);
                setOnline(!!r.admin.online);
            }
        }
        finally { setLoading(false); }
    }

    return (
        <div className='max-w-[90%] mx-auto mb-20'>
            <h2 className="text-2xl flex gap-2 items-center justify-center md:justify-start">
                Settings <IoRefresh className='text-gray-500 cursor-pointer' onClick={() => setRefresh(refresh + 1)} />
            </h2>
            <div className='flex justify-between flex-col bg-white shadow rounded-lg p-5 my-5 w-full md:w-80 gap-5'>
                <div className='flex justify-between'>
                    Users: <p className='text-right'>{users}</p>
                </div>
                <div className='flex justify-between'>
                    <p className='text-gray-500 text-sm'>Admin is Online:</p>
                    <input type="checkbox" id='available' checked={online} onChange={e => UpdateOnline({ online: e.target.checked })} className="checkbox checkbox-accent" />
                </div>
            </div>
        </div>
    );
};

export default AdminSettings;