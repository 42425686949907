import React, { useContext, useEffect, useState } from 'react';
import { IoRefresh } from 'react-icons/io5';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { MyContext } from '../../../App';
import { MyAxios } from '../../../Utils/utils';
import OrderTopup from './1xBet/OrderTopup';
import OrderWIthdraw from './1xBet/OrderWIthdraw';

const SharedOrder = () => {
    const { id } = useParams();
    document.title = `Order ${id} || BetShop`;

    const { uiText, user, setLoading } = useContext(MyContext);
    const [order, setOrder] = useState({});
    const [refresh, setRefresh] = useState(0);
    const [showRefrsh, setShowRefrsh] = useState(true);

    useEffect(() => {
        (async () => {
            setShowRefrsh(false);
            setTimeout(() => setShowRefrsh(true), 1000);
            try {
                setLoading(true);
                const r = await MyAxios.get('/orders/' + id, { jwt: user?.jwt });
                if (!r?.ok) return toast.error(r?.text || "Can not get order data from server");
                if (r?.order) setOrder(r.order);
            }
            finally { setLoading(false); }
        })();
    }, [id, setLoading, refresh, user]);


    if (!order?.product_id) return;
    return (
        <div className='my-10 w-[600px] max-w-[90%] mx-auto'>

            <h2 className="text-2xl text-center flex items-center justify-center gap-2">
                {uiText.orders.orderDetails} {showRefrsh && <IoRefresh className='text-gray-500 cursor-pointer' onClick={() => setRefresh(refresh + 1)} />}
            </h2>

            {order.product_id.includes('1xbet_topup') && <OrderTopup order={order} setOrder={setOrder} />}
            {order.product_id.includes('1xbet_withdraw') && <OrderWIthdraw order={order} setOrder={setOrder} />}
        </div>
    );
};

export default SharedOrder;