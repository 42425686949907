import React, { useContext, useState } from 'react';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { MyContext } from '../../../App';
import { PmNames } from '../../../app.config';
import { handleErrors, MyAxios } from '../../../Utils/utils';

const PayMethod = ({ pm, refrsh, setRefresh }) => {
    const { register, handleSubmit, formState: { errors } } = useForm();
    const { user, setLoading, setUser } = useContext(MyContext);
    const [available, setAvailable] = useState(pm.available);

    const onSubmit = async data => {
        try {
            setLoading(true);
            data.available = available;
            const r = await MyAxios.patch(`/pms/${pm.account}`, { jwt: user.jwt }, { pm: data });
            handleErrors(r, true, setUser);
            if (!r?.ok) return;
            toast.success(r?.text || 'Success');
            setRefresh(refrsh + 1);
        }
        catch (e) { toast.error(e.message || e); }
        finally { setLoading(false); }
    }

    return (
        <div className='flex bg-white flex-col rounded-lg p-6 w-full md:w-[300px] shadow'>
            <h3 className="text-xl font-medium text-center mb-2">{PmNames[pm.account]}</h3>
            <form onSubmit={handleSubmit(onSubmit)}>

                <div className='flex justify-between'>
                    <p className='text-gray-500 text-sm'>Available:</p>
                    <input type="checkbox" id='available' checked={available} onChange={e => setAvailable(e.target.checked)} className="checkbox checkbox-accent" />
                </div>

                <div>
                    <div className='flex justify-between flex-col'>
                        <p className='text-gray-500 text-sm text-left'>{PmNames[pm.account]} Wallet</p>
                        <input type="text" defaultValue={pm?.wallet} className="topup-box focus:border-lime-500 w-full"
                            {...register('wallet', { required: 'Required', minLength: { value: 5, message: 'Minimum 5 characters' } })} />
                    </div>
                    <p className='text-red-300 text-left text-xs'>{errors?.wallet?.message}</p>
                </div>

                <div>
                    <div className='flex justify-between flex-col mt-2'>
                        <p className='text-gray-500 text-sm text-left'>{PmNames[pm.account]} Wallet note</p>
                        <textarea defaultValue={pm?.note} className="topup-box focus:border-lime-500 w-full" {...register('note')} />
                    </div>
                </div>

                <button className={`btn btn-sm block mx-auto mt-4 px-5`} type='input'>Save</button>
            </form>
        </div>
    );
};

export default PayMethod;