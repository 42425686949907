import React, { useContext, useEffect, useState } from 'react';
import { IoRefresh } from 'react-icons/io5';
import { MyContext } from '../../App';
import { MyAxios } from '../../Utils/utils';
import BuySell from './Home/BuySell';
import HowItWorks from './Home/HowItWorks';
import Products from './Home/Products';
import RecentOrders from './Home/RecentOrders';
import WhyUs from './Home/WhyUs';

const Home = () => {
    const { setUser, setUserLoading, setLoading } = useContext(MyContext);
    document.title = 'Home || BetShop';
    const [products, setProducts] = useState([]);
    const [orders, setOrders] = useState([]);
    const [adminOnline, setAdminOnline] = useState(false);
    const [refresh, setRefresh] = useState(0);
    const [showRefresh, setshowRefresh] = useState(true);

    // load homepage data
    useEffect(() => {
        (async () => {
            try {
                setLoading(true);
                setshowRefresh(false);
                setUserLoading(true);
                const r = await MyAxios.get('/home' + (localStorage.getItem('jwt') ? '?jwt=' + localStorage.getItem('jwt') : ''), {});
                if (r?.products) setProducts(r.products);
                if (r?.orders) setOrders(r.orders);
                if (r?.user) setUser(r.user);
                setAdminOnline(r?.adminOnline === true);
            }
            finally {
                setLoading(false);
                setUserLoading(false);
                setTimeout(() => setshowRefresh(true), 2000);
            }
        })();
    }, [setLoading, setUser, setUserLoading, refresh]);
    if (products) { }

    return (
        <div>

            {/* Admin Online Status */}
            <div className={`admin__status flex items-center hover:left-0`}>
                <div className='bg-white py-2 pl-3 pr-2 rounded-lg rounded-l-none'>
                    admin <br /> <span className='font-medium'>{adminOnline ? 'Online' : 'Offline'}</span>
                    <br />
                    <IoRefresh className={`text-gray-500 text-2xl ml-2 cursor-pointer ${!showRefresh ? 'hidden' : ''}`} onClick={() => setRefresh(refresh + 1)} />
                </div>
                <div className='bg-white p-1 h-8 w-8 rounded-full rounded-l-none flex items-center justify-center indicator__'>
                    <div className={`block h-2 w-2 rounded-full ${adminOnline ? 'bg-green-400' : 'bg-red-400'}`}><div></div></div>
                </div>
            </div>

            <BuySell />
            <Products products={products} />
            <HowItWorks />
            <WhyUs />
            <RecentOrders orders={orders} />
        </div>
    );
};

export default Home;