import React, { useState } from 'react';
import { useEffect } from 'react';
import { useContext } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { MyContext } from '../../../App';
import { ImageHost } from '../../../app.config';
import { MyAxios, productNameById, Utils } from '../../../Utils/utils';
import NewTopUp from './1xBet/NewTopUp';
import NewWithdraw from './1xBet/NewWithdraw';

const NewOrder = () => {
    document.title = 'New Order || BetShop';
    const { register, formState: { errors }, handleSubmit } = useForm();
    const { uiText, user, setUser, setLoading, recaptchaRef } = useContext(MyContext);
    const [product, setProduct] = useState({});
    const { id } = useParams();
    const [pms, setPms] = useState([]);
    const navigate = useNavigate();

    useEffect(() => {
        (async () => {
            try {
                setLoading(true);
                const r = await MyAxios.get('/products/' + id, { jwt: user?.jwt });
                if (r?.logout) await Utils.LogOut(setUser, setLoading);
                if (!r?.ok || !r?.product || !r?.product?.pms?.[0]) return toast.error((!r?.ok && r?.text) ? r.text : 'Cannot get product data from server');
                setProduct(r.product);
                setPms(r.product.pms.filter(rPm => rPm?.available === true));
            }
            finally { setLoading(false); }
        })();
    }, [id, user, setUser, setLoading]);

    const onSubmit = async order => {
        try {
            setLoading(true);
            const captcha = await recaptchaRef.current.executeAsync();
            const r = await MyAxios.post('/orders/' + id, { jwt: user?.jwt }, { captcha, order });
            if (!r?.ok) return toast.error(r?.text || 'Cannot create order');
            if (r?.id) navigate('/orders/' + r.id);
        }
        catch (e) { toast.error(e.message); }
        finally { setLoading(false); }
    }

    if (!product?.id) return;
    return (
        <div className='w-[1200px] max-w-[90%] flex flex-col md:flex-row bg-white rounded-lg shadow p-5 my-10 mx-auto gap-8'>
            <div className='w-full bg-[#ecefed] flex justify-center items-center max-h-[350px]'>
                <img src={`${ImageHost}/1xbet.jpg`} alt="" className='w-[400px] p-6 md:max-w-[100%]' />
            </div>
            <div className='w-full'>
                <h2 className="text-2xl font-medium text-gray-600">{productNameById(product?.id)}</h2>
                <p className='my-3 text-gray-500'>{product.description}</p>

                {id.toLowerCase().includes('1xbet_topup') && <NewTopUp values={{ errors, handleSubmit, onSubmit, pms, register, product, uiText }} />}
                {id.toLowerCase().includes('1xbet_withdraw') && <NewWithdraw values={{ errors, handleSubmit, onSubmit, pms, register, product, uiText }} />}
            </div>
        </div>
    );
};

export default NewOrder;