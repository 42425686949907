import React from 'react';

const HelpModal = ({ title, body, setHelp }) => {
    return (
        <div className='fixed w-full h-screen flex justify-center items-center fixed top-0 left-0 z-[99999]'>
            <div className="z-50 bg-[#00000040] fixed w-full h-screen flex justify-center items-center">
                <div className='bg-white rounded-lg p-5 w-[350px] max-w-[90%] min-h-[200px] flex flex-col items-center'>
                    <h2 className="text-2xl text-center text-gray-600">{title}</h2>
                    <p className="text-center mt-1 text-gray-500">{body}</p>

                    <button className='btn text-white mt-auto w-[100px]' onClick={() => setHelp([])}>OK</button>
                </div>
            </div>
        </div>
    );
};

export default HelpModal;