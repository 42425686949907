import React from 'react';

const AboutUs = () => {
    document.title = 'About us || BetShop';
    return (
        <div className='max-w-[90%] mx-auto py-10'>

            <h2>About Us!</h2>

            <h3 className='text-enter'>Welcome To <span id="W_Name1">BetShop</span></h3>

            <p>
                <span id="W_Name2">BetShop</span> is a Professional <span id="W_Type1">eCommerce</span> Platform. Here we will provide you only interesting content, which you will like very much. We're dedicated to providing you the best of <span id="W_Type2">eCommerce</span>, with a focus on dependability and <span id="W_Spec">Exchange</span>. We're working to turn our passion for <span id="W_Type3">eCommerce</span> into a booming online website. We hope you enjoy our <span id="W_Type4">eCommerce</span> as much as we enjoy offering them to you.
            </p>

            <p>I will keep posting more important posts on my Website for all of you. Please give your support and love.</p>
            <p className='font-bold text-center'>
                Thanks For Visiting Our Site<br /><br />
                <span className='text-blue-500 font-bold text-center'>Have a nice day!</span>
            </p>

        </div>
    );
};

export default AboutUs;