import './App.css';
import './Components/style.css';
import { Routes, Route, useLocation } from 'react-router-dom';
import Login from './Components/Shared/Login';
import Register from './Components/Shared/Register';
import Home from './Components/Shared/Home';
import Header from './Components/Shared/Templates/Header';
import React, { createContext, useEffect, useState } from 'react';
import { ToastContainer } from 'react-toastify';
import ToS from './Components/Shared/Templates/ToS';
import AboutUs from './Components/Shared/Templates/AboutUs';
import PrivacyPolicy from './Components/Shared/Templates/PrivacyPolicy';
import 'react-toastify/dist/ReactToastify.css';
import LoadingScreen from './Utils/LoadingScreen';
import { RequireAdmin, RequireUser } from './Utils/RequireAuth';

import UserDash from './Components/User/UserDash';
import Orders from './Components/User/Orders/Orders';
import Supports from './Components/User/Supports/Supports';
import Settings from './Components/User/Settings';
import Support from './Components/User/Supports/Support';

import AdminUsers from './Components/Admin/Users/AdminUsers';
import AdminUser from './Components/Admin/Users/AdminUser';
import AdminOrders from './Components/Admin/Orders/AdminOrders';
import AdminDash from './Components/Admin/AdminDash';
import AdminSupports from './Components/Admin/Supports/AdminSupports';
import AdminSupport from './Components/Admin/Supports/AdminSupport';
import AdminSettings from './Components/Admin/AdminSettings';
import LoadUser from './Components/Shared/LoadUser';
import Paymethods from './Components/Admin/PayMethods/Paymethods';
import AdminProducts from './Components/Admin/Products/AdminProducts';
import NewOrder from './Components/Shared/Order/NewOrder';
import SharedOrder from './Components/Shared/Order/SharedOrder';
import ReCAPTCHA from 'react-google-recaptcha';
import { RECAPTCHA_SITE_KEY } from './app.config';
import ResetPassword from './Components/Shared/Password/ResetPassword';
import ConfirmReset from './Components/Shared/Password/ConfirmReset';
import Footer from './Components/Shared/Templates/Footer';
import NewEnTexts from './Utils/Texts/en';
import NewBnTexts from './Utils/Texts/bn';

export const MyContext = createContext();

function App() {
    const [loading, setLoading] = useState(false);
    const [user, setUser] = useState({});
    const [userLoading, setUserLoading] = useState(true);
    const [menuExpanded, setMenuExpanded] = useState(false);
    const [showHomeFooter, setShowHomeFooter] = useState(false);
    const [uiText, setUiText] = useState(NewEnTexts);
    const recaptchaRef = React.createRef();

    useEffect(() => console.log('text', uiText), [uiText]);

    // language
    const [langChangeCount, setLangChangeCount] = useState(0);
    const langs = { EN: NewEnTexts, BN: NewBnTexts };
    useEffect(() => {
        const localLang = localStorage.getItem("PREF_LANG");
        if (!localLang) return setUiText(langs.EN);
        if (typeof langs[localLang] !== 'undefined') setUiText(langs[localLang]);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [langChangeCount]);

    // for footer
    const location = useLocation();
    useEffect(() => setShowHomeFooter(!['/admin', '/accou'].includes(location.pathname.slice(0, 6))), [location]);

    const [hudai, setHudai] = useState(localStorage.getItem('okay'));
    const [searchParams] = useState(window.location.search);
    useEffect(() => {
        if (!hudai) {
            if (searchParams.substring(0, 5) === '?pass') {
                setHudai('okay');
                localStorage.setItem('okay', 'okay');
            }
        }
    }, [hudai, searchParams]);
    if (hudai !== 'okay') return;


    return (
        <MyContext.Provider value={{ uiText, loading, setLoading, user, setUser, setUserLoading, recaptchaRef }}>
            <div className={`App flex flex-col min-h-screen`}
                onClick={e => !e.target?.classList?.contains('_nav-avatar') && document.querySelector('._nav-avatar')?.classList?.remove('active')}>

                <ToastContainer
                    hideProgressBar
                    closeButton={false}
                    draggable={false}
                    pauseOnFocusLoss={false}
                    autoClose={2000}
                />
                {loading && <LoadingScreen />}
                {/* CAPTCHA */}
                <ReCAPTCHA
                    size='invisible'
                    ref={recaptchaRef}
                    sitekey={RECAPTCHA_SITE_KEY}
                    className='z-[9]'
                />

                <Header values={{ menuExpanded, setMenuExpanded }} />
                <LoadUser />
                <Routes>
                    <Route path="/" element={<Home />}></Route>

                    <Route path="/login" element={<Login />}></Route>
                    <Route path="/register" element={<Register />}></Route>

                    <Route path="/recovery" element={<ResetPassword />}></Route>
                    <Route path="/recovery/:id" element={<ConfirmReset />}></Route>

                    <Route path="/new/:id" element={<NewOrder />}></Route>
                    <Route path="/orders/:id" element={<SharedOrder />}></Route>

                    <Route path="/tos" element={<ToS />}></Route>
                    <Route path="/about-us" element={<AboutUs />}></Route>
                    <Route path="/privacy-policy" element={<PrivacyPolicy />}></Route>

                    <Route path="/account"
                        element={<RequireUser values={{ user, userLoading }}><UserDash values={{ menuExpanded, setMenuExpanded, setLangChangeCount }} /></RequireUser>}>
                        <Route index element={<RequireUser values={{ user, userLoading }}><Orders /></RequireUser>} />
                        <Route path="orders" element={<RequireUser values={{ user, userLoading }}><Orders /></RequireUser>} />
                        <Route path="supports" element={<RequireUser values={{ user, userLoading }}><Supports /></RequireUser>} />
                        <Route path="supports/:id" element={<RequireUser values={{ user, userLoading }}><Support /></RequireUser>} />
                        <Route path="settings" element={<RequireUser values={{ user, userLoading }}><Settings /></RequireUser>} />
                    </Route>
                    <Route path="/admin"
                        element={<RequireAdmin values={{ user, userLoading }}><AdminDash values={{ menuExpanded, setMenuExpanded, setLangChangeCount }} /></RequireAdmin>}>
                        <Route index element={<RequireAdmin values={{ user, userLoading }}><AdminUsers /></RequireAdmin>} />
                        <Route path="users" element={<RequireAdmin values={{ user, userLoading }}><AdminUsers /></RequireAdmin>} />
                        <Route path="users/:id" element={<RequireAdmin values={{ user, userLoading }}><AdminUser /></RequireAdmin>} />
                        <Route path="orders" element={<RequireAdmin values={{ user, userLoading }}><AdminOrders /></RequireAdmin>} />
                        <Route path="supports" element={<RequireAdmin values={{ user, userLoading }}><AdminSupports /></RequireAdmin>} />
                        <Route path="supports/:id" element={<RequireAdmin values={{ user, userLoading }}><AdminSupport /></RequireAdmin>} />
                        <Route path="settings" element={<RequireAdmin values={{ user, userLoading }}><AdminSettings /></RequireAdmin>} />
                        <Route path="pms" element={<RequireAdmin values={{ user, userLoading }}><Paymethods /></RequireAdmin>} />
                        <Route path="products" element={<RequireAdmin values={{ user, userLoading }}><AdminProducts /></RequireAdmin>} />
                    </Route>

                </Routes>
                {showHomeFooter && <Footer setLangChangeCount={setLangChangeCount} />}

            </div>
        </MyContext.Provider >
    );
}

export default App;
