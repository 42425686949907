import { inputLengths } from "../../app.config";
import { coinTypeOfPm } from "../utils";

const NewEnTexts = {
    home: {
        home: "Home",
        buySell: "BUY / SELL",
        buySellBody: "Topup or Withdraw from your 1xBet account and much more",
        browseProducts: "Browse Products",
        topProductsToday: "Top Products today",
        howItWorks: {
            howItWorks: "How it Works!",
            oneHead: "1. Create an account",
            oneBody: "All your data is encrypted and stored on secure servers",
            twoHead: "2. Place an order",
            twoBody: "Either you want to topup or withdraw, just place an order",
            threeHead: "3. Get paid in minutes",
            threeBody: "Your order will be completed within minutes of placing!",
        },
        whyUs: {
            whyUs: "Why choose us?",
            whyUs1: "Highly secure and reliable",
            whyUs2: "Best rates on the market",
            whyUs3: "Fast 5-10 min transactions",
            whyUs4: "Fast Support Response",
        },
        recentOrders: "Recent Orders",
    },
    footer: {
        aboutUs: {
            aboutUs: "About Us",
            privacy: "Privacy Policy",
            tos: "Terms of Service",
        },
        account: {
            account: "Account",
            login: "Login",
            register: "Register",
            dashboard: "Dashboard",
        },
        support: {
            support: "Support",
            mobile: "Mobile",
            email: "Email",
            telegram: "Telegram",
        },
        copyright: "All rights reserved",
        copyYears: { '2023': '2023', '2024': '2024', '2025': '2025', }
    },
    orders: {
        order: "Order",
        orders: "Orders",
        orderDetails: "Order Details",
        yourOrders: "Your Orders",
        newOrder: "New Order",
        new: {
            selectPmMethod: "Select Payment Method",
            selectPmMethodHelp: "Select the payment method which you will use to pay us",
            selectPmMethodHelpP: "Select the payment method which we should use to pay you",
            payAmount: "Pay Amount",
            payAmountHelp: "Enter the amount you will pay us via the payment system you choose above",
            wAmount: "Withdraw Amount",
            wAmountHelp: "Enter the amount you will pay us from your 1xBet account",
            onexId: "1xBet Account ID",
            onexIdHelp: "Enter your 1xBet account ID",
            wWallet: "wallet",
            wWalletHelp: "Enter your wallet address for the account your choose above",
            contactEmail: "Contact Email",
            contactEmailHelp: "Enter your email address, we will use this email to contact you in case of any problem",
            showAmt: {
                topup: (showAmt, product) => <p className='text-left text-xs mt-2'>
                    You will get about <span className='font-bold text-green-600'>{showAmt} {product.id.includes('bdt') ? 'BDT' : 'USD'}</span> in your 1xBet account<br />
                    This value may <span className='text-red-400'>change</span>, actual amount will be shown on order confirmation page
                </p>,
                withdraw: (showAmt, product, pm) => <p className='text-left text-xs mt-2'>
                    You will get about <span className='font-bold text-green-600'>{showAmt} {coinTypeOfPm(pm.account)}</span> in your {pm.account} wallet<br />
                    This value may <span className='text-red-400'>change</span>, actual amount will be shown on order confirmation page
                </p>
            }
        },
        waiting: {
            note: "Note:",
            warn: "Please check the order details carefully. If everything is fine proceed with payment, cancel the order otherwise",
            timeLeft: "Time left:",
            topup: {
                youWillGet: (data, product_id) => <p className='mb-3'>
                    You will get <span className='font-bold'>{data.amount} {product_id.includes('USD') ? 'USD' : 'BDT'}</span>
                    &nbsp;in your 1xBet account: <span className="font-bold">{data.onex_id}</span>
                </p>,
                pleasePay: (pm_amount, pm_coin, pm_method) => <>Please pay <span className='font-bold text-green-500'>{pm_amount} {pm_coin}</span> to our {pm_method} wallet</>,
                txId: {
                    txId: account => `Enter ${account} Transaction ID`,
                    required: `Transaction ID required`,
                    err: `Invalid transaction ID`
                }
            },
            withdraw: {
                onexId: "Your 1xBet account ID",
                onexIdErr: "Invalid 1xBet account ID",
                wCode: "1xBet withdraw code",
                wCodeErr: "Invalid withdraw code",
                youWillGet: (data, pm_method, pm_amount, product_id) => <>
                    <p className='mb-3'>
                        You will get <span className='font-bold'>{data.amount} {coinTypeOfPm(pm_method)}</span> in your {pm_method} wallet: <span className="font-bold">{data.wallet}</span>
                    </p>
                    <div>
                        Please create an withdraw request from your 1xBet account.<br />
                        Amount: <span className='font-medium text-green-500'>{pm_amount} {product_id.includes('usd') ? 'USD' : 'BDT'}<br /></span>
                        City: <span className='font-medium text-green-500'>Bhaluka (Mymensingh)<br /></span>
                        Street: <span className='font-medium text-green-500'>Josor Khagrachhari Bandorbon65</span>
                    </div>
                </>
            }
        },
        status: {
            waiting: 'Waiting',
            timeout: 'Timeout',
            pending: 'Processing',
            cancelled: 'Cancelled',
            complete: 'Complete',
        }
    },
    otHeads: {
        product: "Product",
        amount: "Amount",
        payMethod: "Pay Method",
        method: "Method",
        payAmount: "Pay Amount",
        payId: "Pay ID",
        payCode: "Pay Code",
        orderStatus: "Order Status:",
        orderStatuses: {
            waiting: "Waiting for user to make payment.",
            pending: "Order is in pending state, admin will complete it soon.",
            timedout: "Order timed out. If you have already paid, please contact the admin via support ticket or email provided at the bottom of the page.",
            user_cancelled: "The order was cancelled by user.",
            admin_cancelled: "The order was cancelled by admin.",
            cancelled: "The order was cancelled. If you have already paid, please contact the admin via support ticket or email provided at the bottom of the page.",
            complete: "The order is successfully completed.",
        }
    },
    tableHeads: {
        id: "ID",
        date: "Date",
        amount: "Amount",
        product: "Product",
        message: "Message",
        status: "Status",
        user: "User",
    },
    formFields: {
        save: "Save",
        name: "Name",
        email: "Email",
        password: "Password",
        newPassword: "New Password",
        currentPassword: "Current Password",
        confirmPassword: "Confirm Password",
        submit: "Submit",
        reset: "Reset",
        login: "Login",
        register: "Register",
        resend: "Resend",
        send: "Send",
        goBack: "Go Back",
        change: "Change",
        placeOrder: "Place Order",
        cancelOrder: "Cancel Order"
    },
    formErrors: {
        code: {
            required: "Verify Code required",
            minLength: `Minimum ${inputLengths.passVerifyCodeMin} characters`,
            maxLength: `Maximum ${inputLengths.passVerifyCodeMax} characters`,
            regex: `Invalid code format`,
        },
        name: {
            required: "Name required",
            minLength: `Minimum ${inputLengths.nameMin} characters`,
            maxLength: `Maximum ${inputLengths.nameMax} characters`,
        },
        email: {
            required: "Email required",
            minLength: `Minimum ${inputLengths.emailMin} characters`,
            maxLength: `Maximum ${inputLengths.emailMax} characters`,
        },
        pass: {
            notMatch: "Passwords does not match",
            required: "Password required",
            newRequired: "New Password required",
            confirmRequired: "Confirm Password required",
            currentRequired: "Current Password required",
            maxLength: `Maximum ${inputLengths.passMax} characters`,
            minLength: `Minimum ${inputLengths.passMin} characters`,
        },
        msg: {
            required: "Message required",
            minLength: `Please entrer a long message`,
            maxLength: `Maximum 500 characters`,
        },
        payAmt: {
            required: "Payment amount required",
            min: min => `Minimum ${min}`,
            max: max => `Maximum ${max}`,
            pattern: `Invalid amount`,
        },
        withdrawAmt: {
            required: "Withdraw amount required",
            min: min => `Minimum ${min}`,
            max: max => `Maximum ${max}`,
            pattern: `Invalid withdraw amount`,
        },
        onexId: {
            required: "1xBet ID required",
            minLength: `Minimum 9 digits`,
            maxLength: `Maximum 9 digits`,
            pattern: `Only number digits allowed`,
        },
        wWallet: {
            required: "Wallet required",
            pattern: `Invalid wallet`,
        },
        contactEmail: {
            required: "Contact email required",
            minLength: `Minimum 5 characters`,
            maxLength: `Maximum 50 characters`
        }
    },
    login: {
        login: "Login",
        loginBody: "Login to your account",
        newHere: "New here?",
        forgotPassword: "Forgot Password?",
    },
    recovery: {
        resendIn: "Resend in:",
        recovery: "Password Recovery",
        recoveryBody: "Recover your account password",
        goBack: "Go back to",
        confirmYourEmail: "Confirm your email",
        codeFromMail: "Verification code from email",
        didntReceivedMail: "Didn't Received the mail?",
    },
    register: {
        register: "Create an Account",
        registerBody: "Create a new account to purchase products and much more!",
        haveAccount: "Have account?",
    },
    supports: {
        new: "New",
        close: "Close this ticket",
        ticket: "Ticket",
        support: "Support",
        supportCenter: "Support Center",
        yourSupports: "Your Support Tickets",
        enterYourMsg: "Enter your message",
    },
    tosAgreement: "By using our website, you agree to our Privacy Policy and Terms of Service",
    pagination: {
        page: "Page",
        of: "of"
    },
    settings: {
        settings: "Settings",
        accountSettings: "Account Settings",
        yourBSId: "Your Betshop ID",
        changePass: "Change Password",
        zoom: "Zoom",
        rotate: "Rotate",
        errors: {
            selectImg: "Please select an image",
            onlyJpgPng: "Only JPG and PNG are allowed",
            unknownIsImgSelected: "Unknown error. Have you selected any file?",
            fileTooLarge: "File too large, try adjusting the image or choose another file",
            coudntUpdate: "Could not update profile picture",
            success: "Updated successfully"
        }
    },

    navMenus: {
        orders: "Orders",
        ordersList: "Orders List",
        supports: "Supports",
        settings: "Settings",
        logout: "Logout",
        home: "Home",
        dash: "Dashboard",
        payMethods: "Pay Methods",
        products: "Products",
        users: "Users",
    }
}




export default NewEnTexts;