import React from 'react';
import { Link } from 'react-router-dom';

const ToS = () => {
    document.title = 'Term of Service || BetShop';
    return (
        <div className='max-w-[90%] mx-auto py-10'>

            <h1 className='text-4xl text-gray-600'>Terms and Conditions of Use</h1>

            <p className='text-gray-500 mt-5'>Last updated: November 01, 2022</p>
            <p className='text-gray-500 mt-5'>By accessing this Website, accessible from https://betshop.pro, you are agreeing to be bound by these Website Terms and Conditions of Use and agree that you are responsible for the agreement with any applicable local laws. If you disagree with any of these terms, you are prohibited from accessing this site. The materials contained in this Website are protected by copyright and trade mark law.</p>

            <h2 className='text-xl mt-5 mb-1'>2. Use License</h2>

            <p className='text-gray-500'>Permission is granted to temporarily download one copy of the materials on betshop.pro's Website for personal, non-commercial transitory viewing only. This is the grant of a license, not a transfer of title, and under this license you may not:</p>

            <ul className='list-disc pl-12 py-2 text-gray-500'>
                <li>modify or copy the materials;</li>
                <li>use the materials for any commercial purpose or for any public display;</li>
                <li>attempt to reverse engineer any software contained on betshop.pro's Website;</li>
                <li>remove any copyright or other proprietary notations from the materials; or</li>
                <li>transferring the materials to another person or "mirror" the materials on any other server.</li>
            </ul>

            <p className='text-gray-500'>This will let betshop.pro to terminate upon violations of any of these restrictions. Upon termination, your viewing right will also be terminated and you should destroy any downloaded materials in your possession whether it is printed or electronic format. These Terms of Service has been created with the help of the <a href="https://www.termsofservicegenerator.net">Terms Of Service Generator</a>.</p>

            <h2 className='text-xl mt-5 mb-1'>3. Disclaimer</h2>

            <p className='text-gray-500'>All the materials on betshop.pro’s Website are provided "as is". betshop.pro makes no warranties, may it be expressed or implied, therefore negates all other warranties. Furthermore, betshop.pro does not make any representations concerning the accuracy or reliability of the use of the materials on its Website or otherwise relating to such materials or any sites linked to this Website.</p>

            <h2 className='text-xl mt-5 mb-1'>4. Limitations</h2>

            <p className='text-gray-500'>betshop.pro or its suppliers will not be hold accountable for any damages that will arise with the use or inability to use the materials on betshop.pro’s Website, even if betshop.pro or an authorize representative of this Website has been notified, orally or written, of the possibility of such damage. Some jurisdiction does not allow limitations on implied warranties or limitations of liability for incidental damages, these limitations may not apply to you.</p>

            <h2 className='text-xl mt-5 mb-1'>5. Revisions and Errata</h2>

            <p className='text-gray-500'>The materials appearing on betshop.pro’s Website may include technical, typographical, or photographic errors. betshop.pro will not promise that any of the materials in this Website are accurate, complete, or current. betshop.pro may change the materials contained on its Website at any time without notice. betshop.pro does not make any commitment to update the materials.</p>

            <h2 className='text-xl mt-5 mb-1'>6. Links</h2>

            <p className='text-gray-500'>betshop.pro has not reviewed all of the sites linked to its Website and is not responsible for the contents of any such linked site. The presence of any link does not imply endorsement by betshop.pro of the site. The use of any linked website is at the user’s own risk.</p>

            <h2 className='text-xl mt-5 mb-1'>7. Site Terms of Use Modifications</h2>

            <p className='text-gray-500'>betshop.pro may revise these Terms of Use for its Website at any time without prior notice. By using this Website, you are agreeing to be bound by the current version of these Terms and Conditions of Use.</p>

            <h2 className='text-xl mt-5 mb-1'>8. Your Privacy</h2>

            <p className='text-gray-500'>Please read our <Link to='/privacy-policy' className='text-blue-500 hover:underline'>Privacy Policy</Link>.</p>

            <h2 className='text-xl mt-5 mb-1'>9. Governing Law</h2>

            <p className='text-gray-500'>Any claim related to betshop.pro's Website shall be governed by the laws of bd without regards to its conflict of law provisions.</p>




            <h1 className='mt-10 text-2xl mb-1'>Changes to These Terms and Conditions</h1>

            <p className='text-gray-500'>We reserve the right, at Our sole discretion, to modify or replace these Terms at any time. If a revision is material We will make reasonable efforts to provide at least 30 days' notice prior to any new terms taking effect. What constitutes a material change will be determined at Our sole discretion.</p>

            <p className='text-gray-500 mt-1'>By continuing to access or use Our Service after those revisions become effective, You agree to be bound by the revised terms. If You do not agree to the new terms, in whole or in part, please stop using the website and the Service.</p>




            <h1 className='mt-10 text-2xl mb-1'>Contact Us</h1>
            <p className='text-gray-500'>
                If you have any questions about these Terms and Conditions, You can contact us:
            </p>
            <ul className='list-disc pl-12 py-2 text-gray-500'>
                <li><a href="mailto:support@betshop.pro" className='text-blue-500 hover:underline'>support@betshop.pro</a></li>
            </ul>
        </div>
    );
};

export default ToS;