import React, { useState } from 'react';
import { BsBoxSeam, BsCreditCard, BsNewspaper } from 'react-icons/bs';
import { HiOutlineCog, HiUsers } from 'react-icons/hi';
import { VscSignOut } from 'react-icons/vsc';
import { Link, Outlet, useLocation } from 'react-router-dom';
import { BiSupport } from 'react-icons/bi';
import { useEffect } from 'react';
import { Utils } from '../../Utils/utils';
import { useContext } from 'react';
import { MyContext } from '../../App';
import Footer from '../Shared/Templates/Footer';

const AdminDash = ({ values: { menuExpanded, setMenuExpanded, setLangChangeCount } }) => {

    const { setUser, uiText, setLoading } = useContext(MyContext);
    const [active, setActive] = useState('orders');
    const location = useLocation();

    useEffect(() => {
        const path = location.pathname;
        if (path.includes('orders')) setActive('orders');
        else if (path.includes('supports')) setActive('supports');
        else if (path.includes('users')) setActive('users');
        else if (path.includes('products')) setActive('products');
        else if (path.includes('pms')) setActive('pms');
        else if (path.includes('settings')) setActive('settings');
        setMenuExpanded(false);
    }, [location, setMenuExpanded]);

    return (
        <div className="flex flex-grow">
            <div className={`bg-white shadow mt-[1px] p-4 sidemenu md:fixed h-full flex flex-col gap-1 duration-300 side-menu ${menuExpanded && 'active'} min-w-[250px]`}>
                <Link to='/admin/orders' className={`${active === 'orders' ? 'active' : ''} navlink__item`}>
                    <BsNewspaper /> {uiText.navMenus.orders}
                </Link>
                <Link to='/admin/supports' className={`${active === 'supports' ? 'active' : ''} navlink__item`}>
                    <BiSupport /> {uiText.navMenus.supports}
                </Link>
                <Link to='/admin/users' className={`${active === 'users' ? 'active' : ''} navlink__item`}>
                    <HiUsers /> {uiText.navMenus.users}
                </Link>
                <Link to='/admin/products' className={`${active === 'products' ? 'active' : ''} navlink__item`}>
                    <BsBoxSeam /> {uiText.navMenus.products}
                </Link>
                <Link to='/admin/pms' className={`${active === 'pms' ? 'active' : ''} navlink__item`}>
                    <BsCreditCard /> {uiText.navMenus.payMethods}
                </Link>
                <hr className='my-5' />
                <Link to='/admin/settings' className={`${active === 'settings' ? 'active' : ''} navlink__item`}>
                    <HiOutlineCog /> {uiText.navMenus.settings}
                </Link>
                <button onClick={() => { Utils.LogOut(setUser, setLoading); setMenuExpanded(false); }} className={`bg-gray-100 w-full text-gray-700 navlink__item`}>
                    <VscSignOut /> {uiText.navMenus.logout}
                </button>
            </div>
            <div className='w-[1px] md:min-w-[250px]'></div>
            <div className="screen-max-w overflow-x-auto p-5 pt-10 lg:p-10 !pb-0 flex flex-col justify-between">
                <Outlet />
                <Footer setLangChangeCount={setLangChangeCount} />
            </div>
        </div>
    );
};

export default AdminDash;