import React from 'react';
import { Oval } from 'react-loader-spinner';

const LoadingScreen = () => {
    return (
        <div className="spinner fixed w-full h-screen flex justify-center items-center bg-zinc-200 opacity-75">
            <Oval
                visible={true}
                height="80"
                width="80"
                ariaLabel="dna-loading"
                wrapperStyle={{}}
                wrapperClass="dna-wrapper"
            />
        </div>
    );
};

export default LoadingScreen;