import React, { useEffect } from 'react';
import { useContext } from 'react';
import { BsArrowRightShort } from 'react-icons/bs';
import { MyContext } from '../../../App';
import bg from './../../../src/bg/hex.jpg'

const BuySell = () => {

    const { uiText } = useContext(MyContext);

    useEffect(() => console.log('llllllllll', typeof uiText), [uiText]);

    return (
        <div className={`bg-no-repeat bg-cover bg-bottom buy-sell relative -top-[8vh]`}>
            <img src={bg} alt="" className='h-[70vh] w-full' />
            <div className='text-center'>
                <div className="text-center text-white text-4xl font-bold">{uiText.home.buySell}</div>
                <div className="text-center text-white text-lg px-10 mt-4">{uiText.home.buySellBody}</div>
                <div className="flex justify-center mt-4">
                    <a href='/#products' className='duration-300 flex items-center justify-center gap-2 font-medium rounded-[20px] py-[8px] px-4 mx-1 bg-[#ecefed] hover:bg-gray-300'>
                        {uiText.home.browseProducts} <BsArrowRightShort className='text-2xl' />
                    </a>
                </div>
            </div>
        </div>
    );
};

export default BuySell;