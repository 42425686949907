import React, { useContext } from 'react';
import { MyContext } from '../../../App';

const HowItWorks = () => {

    const { uiText } = useContext(MyContext);

    return (
        <div className='m-4 md:m-10 my-20 md:my-20'>
            <h2 className="text-3xl text-center">{uiText.home.howItWorks.howItWorks}</h2>
            <div className='flex flex-wrap justify-center items-center m-4 gap-5 md:gap-2'>

                <div className="bg-white shadow-md w-[300px] max-w-[100%] rounded-full aspect-square flex p-4 how-div rotate-[-10deg]">
                    <div className='flex flex-col justify-center items-center text-center '>
                        <img src="/src/images/account.svg" alt="" className='h-10' />
                        <p className="font-medium py-2">{uiText.home.howItWorks.oneHead}</p>
                        <p className="text-gray-400">{uiText.home.howItWorks.oneBody}</p>
                    </div>
                </div>

                <img src="/src/images/dots.png" alt="" className='hidden lg:block' />

                <div className="bg-white shadow-md w-[300px] max-w-[100%] rounded-full aspect-square flex p-4 how-div">
                    <div className='flex flex-col justify-center items-center text-center '>
                        <img src="/src/images/computer.svg" alt="" className='h-10' />
                        <p className="font-medium py-2">{uiText.home.howItWorks.twoHead}</p>
                        <p className="text-gray-400">{uiText.home.howItWorks.twoBody}</p>
                    </div>
                </div>

                <img src="/src/images/dots.png" alt="" className='hidden lg:block' />

                <div className="bg-white shadow-md w-[300px] max-w-[100%] rounded-full aspect-square flex p-4 how-div rotate-[10deg]">
                    <div className='flex flex-col justify-center items-center text-center '>
                        <img src="/src/images/check.svg" alt="" className='h-10' />
                        <p className="font-medium py-2">{uiText.home.howItWorks.threeHead}</p>
                        <p className="text-gray-400">{uiText.home.howItWorks.threeBody}</p>
                    </div>
                </div>

            </div>
        </div>
    );
};

export default HowItWorks;