import React, { useContext, useEffect, useState } from 'react';
import { readAndCompressImage } from 'browser-image-resizer';
import { useForm } from 'react-hook-form';
import { IoCloseCircleOutline } from 'react-icons/io5';
import { IoIosArrowForward } from 'react-icons/io';
import { toast } from 'react-toastify';
import { handleErrors, MyAxios } from '../../Utils/utils';
import { MyContext } from '../../App';
import { ImageHost, inputLengths } from '../../app.config';
import AvatarEditor from 'react-avatar-editor'
import { useRef } from 'react';
import { TogglePassword } from '../../Hooks/TogglePassword';
const base64js = require('base64-js')


const Settings = () => {
    document.title = 'Settings || BetShop';
    const { type: passwordType, icon: passwordIcon, toggle: passwordToggle } = TogglePassword();
    const { user, uiText, setUser, setLoading } = useContext(MyContext);
    const { register, formState: { errors }, handleSubmit, reset } = useForm();
    const [changePass, setChangePass] = useState(false);
    const [newPassword, setNewPassword] = useState('');
    const [showSave, setShowSave] = useState(true);

    const [editImage, setEditImage] = useState();
    const [rotation, setRotation] = useState(0);
    const [scale, setScale] = useState(1);
    const editorRef = useRef(null);

    useEffect(() => reset({ name: user?.name, email: user?.email }), [reset, user, changePass]);

    const onSelectFile = async e => {
        const file = e.target.files[0];
        if (!file) {
            toast.error(uiText.settings.errors.selectImg);
            return setEditImage(undefined);
        }
        if (['image/jpeg', 'image/png'].includes(file?.type) === false) return toast.error(uiText.settings.errors.onlyJpgPng);
        const objectUrl = URL.createObjectURL(file)
        setScale(1);
        setRotation(0);
        setEditImage(objectUrl);
        return () => URL.revokeObjectURL(objectUrl)
    }


    const ChangePicture = async () => {
        try {
            setLoading(true);
            let preview;
            if (editorRef.current) preview = editorRef.current.getImage().toDataURL('image/*', 0.1);
            if (!preview) return toast.error(uiText.settings.errors.selectImg);
            const formData = new FormData();
            const b64 = base64js.toByteArray(preview?.split(";base64,")?.[1]);
            if (!b64) return toast.error(uiText.settings.errors.unknownIsImgSelected);
            const blob = new Blob([b64], { type: 'image/*' });
            const newImg = await readAndCompressImage(blob, { maxHeight: 1000, maxWidth: 1000, quality: 1 });
            if (newImg.size > 3145728) return toast.error(uiText.settings.errors.fileTooLarge);
            formData.append("pic", newImg);
            const r = await MyAxios.post(`/users/${user.id}`, { jwt: user.jwt }, formData);
            if (!r?.ok || !r?.user) return toast.error(r?.text || uiText.settings.errors.coudntUpdate);
            toast.success(uiText.settings.errors.success);
            if (r?.user) setUser(r.user);
            setEditImage(undefined);
            document.getElementById('file').value = null;
        }
        catch (e) { toast.error(e.message); }
        finally { setLoading(false); }
    }

    const onSubmit = async data => {
        try {
            setLoading(true);
            setShowSave(false);
            const r = await MyAxios.patch(`/users/` + user.id, { jwt: user.jwt }, { user: data });
            handleErrors(r, true, setUser);
            if (r?.user) {
                toast.success(uiText.settings.errors.success);
                setUser(r.user);
            }
        }
        finally { setLoading(false); setTimeout(() => setShowSave(true), 2000); }
    }

    return (
        <div>
            <h2 className="text-2xl text-center md:text-left">{uiText.settings.settings}</h2>
            <div className='bg-white p-4 my-4 shadow rounded-lg'>
                <h3 className="font-medium mb-5">
                    {uiText.settings.accountSettings}
                </h3>
                <div className="mt-10">
                    <div className=' max-w-[100%] w-[500px] mx-auto pt-2'>
                        <div className={`rounded-full border w-[200px] h-[200px] overflow-hidden mx-auto bg-no-repeat bg-center bg-cover`}
                            style={{ backgroundImage: `url('${ImageHost}/${!user?.logo ? 'avatar.svg' : user.logo}')` }}>
                        </div>
                        <label className="bg-red-100 block w-32 mt-2 cursor-pointer text-center mx-auto">
                            <input type='file' onChange={onSelectFile} className='hidden' accept='.jpg, .png' id='file' />
                            {uiText.formFields.change}
                        </label>

                        <form className='pt-10 pb-5' onSubmit={handleSubmit(onSubmit)}>

                            {/* betshop id */}
                            <div className='relative ps flex flex-col text-left text-gray-600 mt-4'>
                                <p className='text-xs'>{uiText.settings.yourBSId}</p>
                                <input type="text" className='topup-box focus:border-lime-500' defaultValue={user?.num} disabled />
                            </div>

                            {/* name */}
                            <div className="form-control">
                                <label className="label pb-0 pl-0"><span className="label-text">{uiText.formFields.name}</span> </label>
                                <input type="text" className="topup-box focus:border-lime-500" {...register("name", {
                                    required: uiText.formErrors.name.required,
                                    minLength: { value: inputLengths.nameMin, message: uiText.formErrors.name.minLength },
                                    maxLength: { value: inputLengths.nameMax, message: uiText.formErrors.name.maxLength },
                                })} />
                                <label className="label pl-1 pt-1"><span className="label-text-alt text-red-400">{errors.name?.message}</span></label>
                            </div>

                            {/* password */}
                            <div className='relative ps flex flex-col text-left text-gray-600 mt-4'>
                                <label className={`cursor-pointer select-none`} onClick={() => setChangePass(!changePass)} >
                                    <IoIosArrowForward className={`inline mb-1 text-2xl mr-1 ${changePass ? 'rotate-90' : ''}`} />
                                    {uiText.settings.changePass}
                                </label>
                            </div>
                            {changePass && <>
                                {/* current password */}
                                <div className='relative ps flex flex-col text-left text-gray-600 ml-6'>
                                    <p className='text-xs'>{uiText.formFields.currentPassword}</p>
                                    <input type={passwordType} className='topup-box focus:border-lime-500' {...register('password', {
                                        required: uiText.formErrors.pass.required,
                                        minLength: { value: inputLengths.passMin, message: uiText.formErrors.pass.minLength },
                                        maxLength: { value: inputLengths.passMax, message: uiText.formErrors.pass.maxLength },
                                    })} />
                                    <p className="text-red-300 text-xs">{errors?.password?.message}</p>
                                    <div className="absolute top-[23px] h-[37px] right-0 cursor-pointer w-[50px] flex justify-center items-center" onClick={passwordToggle}>{passwordIcon}</div>
                                </div>

                                {/* new password */}
                                <div className='relative ps flex flex-col text-left text-gray-600 mt-2 ml-6'>
                                    <p className='text-xs'>{uiText.formFields.newPassword}</p>
                                    <input type={passwordType} className='topup-box focus:border-lime-500' {...register('new_password', {
                                        onChange: e => setNewPassword(e.target.value),
                                        required: uiText.formErrors.pass.newRequired,
                                        minLength: { value: inputLengths.passMin, message: uiText.formErrors.pass.minLength },
                                        maxLength: { value: inputLengths.passMax, message: uiText.formErrors.pass.maxLength },
                                    })} />
                                    <p className="text-red-300 text-xs">{errors?.new_password?.message}</p>
                                    <div className="absolute top-[23px] h-[37px] right-0 cursor-pointer w-[50px] flex justify-center items-center" onClick={passwordToggle}>{passwordIcon}</div>
                                </div>

                                {/* confirm password */}
                                <div className='relative ps flex flex-col text-left text-gray-600 mt-2 ml-6'>
                                    <p className='text-xs'>{uiText.formFields.confirmPassword}</p>
                                    <input type={passwordType} className='topup-box focus:border-lime-500' {...register('confirm_password', {
                                        required: uiText.formErrors.pass.confirmRequired,
                                        validate: v => v === newPassword ? null : uiText.formErrors.pass.notMatch
                                    })} />
                                    <p className="text-red-300 text-xs">{errors?.confirm_password?.message}</p>
                                    <div className="absolute top-[23px] h-[37px] right-0 cursor-pointer w-[50px] flex justify-center items-center" onClick={passwordToggle}>{passwordIcon}</div>
                                </div>
                            </>}

                            <input type="submit" className='btn btn-success mx-auto w-[200px] text-white block mt-10' value={uiText.formFields.save} disabled={!showSave} />

                        </form>
                    </div>
                </div>
            </div>


            {/* Preview image when selected */}
            {editImage && <div className='fixed w-full bg-[#00000040] h-screen w-full left-0 top-0 flex items-center justify-center'>
                <div className="bg-white rounded-lg w-[500px] max-w-[90%] pb-10 flex justify-center items-center flex-col">
                    <div className='flex justify-end w-full p-2 text-2xl text-gray-500'>
                        <IoCloseCircleOutline className='closeDisplayPopup cursor-pointer' onClick={() => { setEditImage(undefined); document.getElementById('file').value = null; }} />
                    </div>
                    <div className="mx-auto mt-5">
                        <AvatarEditor
                            ref={editorRef}
                            image={editImage}
                            width={250}
                            height={250}
                            border={10}
                            color={[0, 255, 0, 0.5]} // RGBA
                            scale={scale}
                            rotate={rotation}
                            borderRadius={99999}
                        />
                        {uiText.settings.zoom} <br /> <input defaultValue="1" type="range" min="1" max="3" step="0.01" onChange={e => setScale(parseFloat(e.target.value))} className='w-full' />
                        <br />
                        {uiText.settings.rotate} <br /> <input defaultValue="0" type="range" min="-360" max="360" step="5" onChange={e => setRotation(parseFloat(e.target.value))} className='w-full' />
                    </div>
                    <button className="btn btn-success mt-4" onClick={ChangePicture}>{uiText.formFields.submit}</button>
                </div>
            </div>}
        </div >
    );
};

export default Settings;