import React, { useContext, useState } from 'react';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { IoRefresh } from 'react-icons/io5';
import { Link, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { MyContext } from '../../../App';
import { handleErrors, MyAxios, Utils } from '../../../Utils/utils';
import NewSupport from './NewSupport';
import SupportResource from './SupportsResource';

const Support = () => {
    const { register, formState: { errors }, handleSubmit, reset } = useForm();
    const { user, uiText, setUser, recaptchaRef, setLoading } = useContext(MyContext);
    const { id } = useParams();
    document.title = `Support ${id} || BetShop`;
    const [support, setSupport] = useState({});
    const [refresh, setRefresh] = useState(0);
    const [showRefresh, setShowRefresh] = useState(true);

    /* Load support ticket details */
    useEffect(() => {
        (async () => {
            if (id.toLowerCase() === 'new') return;
            try {
                setLoading(true);
                setShowRefresh(false);
                const r = await MyAxios.get('/supports/' + id, { jwt: user.jwt });
                if (!r?.ok) return toast.error(r?.text || 'Unknown error. code -100');
                if (r?.support) setSupport(r.support);
            }
            finally { setLoading(false); setTimeout(() => setShowRefresh(true), 1000); }
        })()
    }, [id, setLoading, user, refresh]);

    /* Updates support ticket */
    const onSubmit = async data => {
        try {
            setLoading(true);
            const captcha = await recaptchaRef.current.executeAsync();
            const r = await MyAxios.patch(`/supports/` + id, { jwt: user.jwt }, { captcha, support: data });
            handleErrors(r, true, setUser);
            if (r?.support) {
                setSupport(r.support);
                reset();
            }
        }
        finally { setLoading(false); }
    }

    if (id.toLowerCase() === 'new') return <NewSupport user={user} setLoading={setLoading} />
    if (!support?.status) return;
    return (
        <div>

            <h2 className="text-2xl flex gap-2 items-center justify-center md:justify-start">
                {uiText.supports.supportCenter} <IoRefresh className={`text-gray-500 cursor-pointer ${showRefresh ? '' : 'hidden'}`} onClick={() => setRefresh(refresh + 1)} />
            </h2>

            <div className='bg-white p-4 my-4 shadow rounded-lg'>

                {/* Support Ticket */}
                <h3 className="mb-5"><span>{uiText.supports.ticket} #{id}</span>
                    <span className={`${support.status === 'open' ? 'bg-green-200' : 'bg-yellow-200'} font-medium px-3 py-1 rounded-lg text-gray-500 mx-3`}>{support.status}</span>
                    {support.status === 'open' && <button className='btn btn-error btn-sm min-w-[200px] mt-2' onClick={e => onSubmit({ status: 'close' })}>{uiText.supports.close}</button>}
                </h3>
                <div>
                    {support.texts.map((text, i) => <div key={`${i}`} className={`flex gap-1 my-2 p-1`}>
                        <img src={SupportResource.getAvatar(text.sender, user.logo)}
                            className='rounded-full w-[2.9rem] md:w-10 h-10 object-cover border-2 border-green-500' alt={text.sender}
                        />
                        <div className={`${text.sender === 'user' ? 'bg-blue-100' : 'bg-gray-100'} rounded flex flex-col p-1 px-2 gap-2 w-full`}>
                            <div className='flex justify-start text-gray-500'>
                                <span className='text-xs'>{text.sender === 'admin' ? 'Support Agent' : user.name}</span>
                                <span className='text-xs ml-2'>{Utils.dateFromTime(text.time)}</span>
                            </div>
                            {text.text}
                        </div>
                    </div>)}
                </div>
                {/* Support Ticket */}


                {/* Support Reply */}
                {support.status === 'open' && <form className='mt-10' onSubmit={handleSubmit(onSubmit)}>
                    <p>{uiText.supports.enterYourMsg}</p>
                    <textarea className='w-full outline-none px-4 py-2 rounded border-2 border-gray-300 focus:border-lime-500 outline-0 duration-300' {
                        ...register('text', {
                            required: uiText.formErrors.msg.required,
                            minLength: { value: 2, message: uiText.formErrors.msg.minLength },
                            maxLength: { value: 500, message: uiText.formErrors.msg.maxLength }
                        })
                    }></textarea>
                    <p className="text-xs text-red-400 mt-[-5px] mb-2">{errors?.text?.message}</p>
                    <button className='btn btn-info btn-sm block mx-auto mt-4'>{uiText.formFields.send}</button>
                </form>}
                {support.status === 'closed' && <div className='text-center mt-5'>
                    <Link to='/account/supports' className='btn btn-success mx-auto'>{uiText.formFields.goBack}</Link>
                </div>}
                {/* Support Reply */}

            </div>
        </div>
    );
};

export default Support;