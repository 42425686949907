import React, { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { handleErrors, MyAxios, Utils } from '../../../Utils/utils';
import { MyContext } from '../../../App';
import { IoRefresh } from 'react-icons/io5';

const AdminSupports = () => {
    document.title = 'Support Tickets || BetShop';
    const { user, setUser, setLoading } = useContext(MyContext);

    const [supports, setSupports] = useState([]);
    const [refresh, setRefresh] = useState(0);
    const [filterPage, setFilterPage] = useState(1);
    const [pages, setPages] = useState(1);

    useEffect(() => {
        (async () => {
            try {
                setLoading(true);
                const r = await MyAxios.get('/supports/all', { jwt: user.jwt });
                handleErrors(r, true, setUser);
                if (r?.supports) setSupports(r.supports);
                if (r?.pages) setPages(r.pages);
            }
            finally { setLoading(false); }
        })();
    }, [setLoading, user, setUser, refresh, filterPage]);

    return (
        <div>
            <h2 className="text-2xl flex gap-2 items-center justify-center md:justify-start">
                Support Tickets <IoRefresh className={`text-gray-500 cursor-pointer`} onClick={() => setRefresh(refresh + 1)} />
            </h2>
            <div className='bg-white p-4 my-4 shadow rounded-lg'>
                <h3 className="font-medium mb-5">Support Tickets</h3>
                <div className='overflow-x-auto'>
                    <table className='w-full text-left support-table'>
                        <thead>
                            <tr className='bg-gray-100'>
                                <th>ID</th>
                                <th>Date</th>
                                <th>User</th>
                                <th>Message</th>
                            </tr>
                        </thead>
                        <tbody>
                            {supports.map((ticket, i) => <tr className='border-b h-[50px]' key={i}>
                                <td className='min-w-[110px]'><Link to={`/admin/supports/${ticket?.id}`} className='text-blue-500 hover:underline'>{ticket?.id}</Link></td>
                                <td className='min-w-[160px]'>{Utils.dateFromTime(ticket?.created_at || 0)}</td>
                                <td className='min-w-[160px]'>{ticket?.name}</td>
                                <td className='min-w-[300px]'>{ticket?.last_text}</td>
                            </tr>)}
                        </tbody>
                    </table>
                </div>

                {pages > 1 && <div className='mt-5 text-center'>
                    <small>Page</small>
                    <select onChange={e => setFilterPage(Number(e.target.value))} className='border mx-2 w-16 cursor-pointer outline-none text-center'>
                        {[...Array(pages)].map((v, i) => <option value={i + 1} key={`page${i}`}>{i < 9 ? '0' + (i + 1) : (i + 1)}</option>)}
                    </select>
                    <small>of {pages < 9 ? '0' + pages : pages}</small>
                </div>}

            </div>
        </div>
    );
};

export default AdminSupports;