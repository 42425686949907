import React, { useContext } from 'react';
import { FiTrendingUp } from 'react-icons/fi';
import { BiSupport } from 'react-icons/bi';
import { MdOutlineSecurity } from 'react-icons/md';
import { AiOutlineClockCircle } from 'react-icons/ai';
import { MyContext } from '../../../App';


const WhyUs = () => {

    const { uiText } = useContext(MyContext);

    return (
        <div className='my-4 md:mt-10 mt-20 md:mt-20 pt-10 why-us bg-no-repeat bg-cover bg-bottom pb-28'>
            <h2 className="text-2xl md:text-3xl text-center pb-6">{uiText.home.whyUs.whyUs}</h2>
            <div className='flex flex-wrap justify-center items-center m-4 gap-5'>

                <div className="bg-white shadow-2xl w-[200px] max-w-[100%] rounded-[25px] px-4 py-10 why-div">
                    <div className='flex flex-col justify-center items-center text-center gap-4'>
                        <MdOutlineSecurity className='text-green-400 text-8xl why-icon' />
                        <p className="text-sm">{uiText.home.whyUs.whyUs1}</p>
                    </div>
                </div>

                <div className="bg-white shadow-2xl w-[200px] max-w-[100%] rounded-[25px] px-4 py-10 why-div">
                    <div className='flex flex-col justify-center items-center text-center gap-4'>
                        <FiTrendingUp className='text-green-400 text-8xl why-icon' />
                        <p className="text-sm">{uiText.home.whyUs.whyUs2}</p>
                    </div>
                </div>

                <div className="bg-white shadow-lg w-[200px] max-w-[100%] rounded-[25px] px-4 py-10 why-div">
                    <div className='flex flex-col justify-center items-center text-center gap-4'>
                        <AiOutlineClockCircle className='text-green-400 text-8xl why-icon' />
                        <p className="text-sm">{uiText.home.whyUs.whyUs3}</p>
                    </div>
                </div>

                <div className="bg-white shadow-lg w-[200px] max-w-[100%] rounded-[25px] px-4 py-10 why-div">
                    <div className='flex flex-col justify-center items-center text-center gap-4'>
                        <BiSupport className='text-green-400 text-8xl why-icon' />
                        <p className="text-sm">{uiText.home.whyUs.whyUs4}</p>
                    </div>
                </div>


            </div>
        </div>
    );
};

export default WhyUs;