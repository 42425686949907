import React, { useEffect, useContext, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { handleErrors, MyAxios, time } from '../../../Utils/utils';
import { MyContext } from '../../../App';
import { TogglePassword } from '../../../Hooks/TogglePassword';
import { inputLengths } from '../../../app.config';

const ConfirmReset = () => {
    document.title = 'Confim Password Recovery || BetShop';
    const { register, formState: { errors }, handleSubmit } = useForm();
    const { user, uiText, setLoading, setUser, recaptchaRef } = useContext(MyContext);
    const navigate = useNavigate();
    const { id } = useParams();
    const [email, setEmail] = useState(undefined);
    const [timer, setTimer] = useState("0");
    const [leftTime, setLeftTime] = useState(60);
    const [password, setPassword] = useState('');
    const { type: passwordType, icon: passwordIcon, toggle: passwordToggle } = TogglePassword();

    useEffect(() => {
        if (user?.id) navigate('/');
        const localInfo = localStorage.getItem("recovery");
        if (!localInfo) navigate('/recovery');
        try {
            const newInfo = JSON.parse(localInfo);
            if (!newInfo.email || !newInfo.time) navigate('/recovery');
            setEmail(newInfo.email);
            const newLeftTime = newInfo.time - time();
            setLeftTime(newLeftTime);
        }
        catch (e) { navigate('/'); }
    }, [user, navigate]);

    /* TIMER */
    useEffect(() => {
        leftTime >= 0 && setTimeout(() => {
            const date = new Date(leftTime * 1000);
            const seconds = date.getUTCSeconds();
            const newTime = seconds > 9 ? seconds : '0' + seconds;
            setTimer(newTime);
            setLeftTime(leftTime - 1);
        }, 1000);
    }, [leftTime, navigate]);

    // resend email code
    const resendEmail = async () => {
        try {
            setLoading(true);
            const captcha = await recaptchaRef.current.executeAsync();
            const r = await MyAxios.post('/recover', {}, { captcha, email: email });
            handleErrors(r, true, setUser);
            if (r?.id) {
                localStorage.setItem("recovery", JSON.stringify({ email, time: time() + 60 }));
                navigate(`/recovery/${r.id}`);
            }
        }
        catch (e) { toast.error(e.message); }
        finally { setLoading(false); }
    }

    /* handle form submission */
    const onSubmit = async ({ code, password }) => {
        try {
            setLoading(true);
            const captcha = await recaptchaRef.current.executeAsync();
            const r = await MyAxios.patch(`/recover/${id}`, {}, { captcha, code: code.replaceAll(" ", "").replaceAll("-", ""), password });
            handleErrors(r, true, setUser);
            if (r?.user) setUser(r.user);
        }
        catch (e) { toast.error(e.message); }
        finally { setLoading(false); }
    }


    return (
        <div className='w-[500px] max-w-[90%] bg-white register-box mx-auto my-10 rounded-lg flex flex-col items-center py-10 px-5'>

            <p className="text-2xl font-medium">{uiText.recovery.recovery}</p>
            <small className='text-center'>{uiText.recovery.recoveryBody}</small>

            <form onSubmit={handleSubmit(onSubmit)} className='mt-10 mb-2 w-[400px] max-w-[100%]'>

                {/* resend code */}
                <div className="form-control">
                    {uiText.recovery.didntReceivedMail}
                    {timer !== '00' && <p className="mb-3">{uiText.recovery.resendIn} {timer}</p>}
                    {timer === '00' && <span onClick={resendEmail} className='text-blue-500 hover:underline cursor-pointer mr-auto'>{uiText.formFields.resend}</span>}
                </div>

                {/* code */}
                <div className="form-control">
                    <label className="label pb-1 pl-0"><span className="label-text">{uiText.recovery.codeFromMail}</span></label>
                    <input type="text" className="topup-box focus:border-lime-500" {...register("code", {
                        required: uiText.formErrors.code.required,
                        minLength: { value: inputLengths.passVerifyCodeMin, message: uiText.formErrors.code.minLength },
                        maxLength: { value: inputLengths.passVerifyCodeMax, message: uiText.formErrors.code.maxLength },
                        pattern: { value: inputLengths.passVerifyCodeRegex, message: uiText.formErrors.code.regex }
                    })} />
                    <label className="label pl-1 pt-1"><span className="label-text-alt text-red-400">{errors?.code?.message}</span></label>
                </div>

                {/* password */}
                <div className="form-control relative">
                    <label className="label pb-1 pl-0"><span className="label-text">{uiText.formFields.newPassword}</span></label>
                    <input type={passwordType} className="topup-box focus:border-lime-500" {...register("password", {
                        onChange: e => setPassword(e.target.value),
                        required: uiText.formErrors.pass.required,
                        minLength: { value: inputLengths.passMin, message: uiText.formErrors.pass.minLength },
                        maxLength: { value: inputLengths.passMax, message: uiText.formErrors.pass.maxLength },
                    })} />
                    <label className="label pl-1 pt-1"><span className="label-text-alt text-red-400">{errors?.password?.message}</span></label>
                    <div className="absolute top-[35px] h-[43px] right-0 cursor-pointer w-[50px] flex justify-center items-center" onClick={passwordToggle}>{passwordIcon}</div>
                </div>

                {/* confirm password */}
                <div className="form-control relative">
                    <label className="label pb-0 pl-0"><span className="label-text">{uiText.formFields.confirmPassword}</span></label>
                    <input type={passwordType} className="topup-box focus:border-lime-500"{...register("confirmPassword", {
                        required: uiText.formErrors.pass.confirmRequired,
                        validate: v => v !== password ? uiText.formErrors.pass.notMatch : null
                    })} />
                    <label className="label pl-1 pt-1"><span className="label-text-alt text-red-400">{errors?.confirmPassword?.message}</span></label>
                    <div className="absolute top-[35px] h-[37px] right-0 cursor-pointer w-[50px] flex justify-center items-center" onClick={passwordToggle}>{passwordIcon}</div>
                </div>

                {/* submit */}
                <div className="flex justify-center mt-5">
                    <input type="submit" id="submit" className='btn btn-success mx-auto w-[200px] text-white' value={uiText.formFields.reset} />
                </div>

                {/* options */}
                <div className='flex flex-col items-center mt-5'>
                    <div>Go back to &nbsp;<Link to='/login' className='text-blue-500 underline'>Login</Link></div>
                </div>
            </form>

        </div>
    );
};

export default ConfirmReset;