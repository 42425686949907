const SupportContacts = {
    email: 'admin@betshop.pro',
    phone: '+8801711111111',
    tg1: '@BetshopPro',
    tg2: '@BetshopProAdmin',
}
const inputLengths = {
    nameMin: 3,
    nameMax: 20,
    emailMin: 3,
    emailMax: 50,
    passMin: 8,
    passMax: 40,
    onexBetIdMin: 9,
    onexBetIdMax: 9,
    bkashMin: 11,
    bkashMax: 11,
    passVerifyCodeMin: 6,
    passVerifyCodeMax: 9,
    passVerifyCodeRegex: /^[0-9- ]{6,9}$/,
}
const RECAPTCHA_SITE_KEY = '6LemfPojAAAAACo4eknsynmuXz4370x_xiE0rYy6';
const OrderStatusTexts = {
    waiting: 'Waiting for users payment.',
    pending: 'Order is in pending state, admin will complete it soon.',
    cancelled: 'Order is cancelled. If you have already paid, please contact the admin via support ticket or email provided at the bottom of the page.',
    complete: 'Order is completed successfully.'
}
const PmNames = {
    BKASH: 'বিকাশ',
    ROCKET: 'নগদ',
    NAGAD: 'রকেট'
}
const SupportSubjects = {
    '1xbet_topup_bdt': '1xBet Topup (BDT)',
    '1xbet_topup_usd': '1xBet Topup (USD)',
    '1xbet_withdraw_bdt': '1xBet Withdraw (BDT)',
    '1xbet_withdraw_usd': '1xBet Withdraw (USD)',
    'other': 'Other...'
}

const ImageHost = `https://bspic.sgp1.cdn.digitaloceanspaces.com/betshop`;

export { RECAPTCHA_SITE_KEY, OrderStatusTexts, PmNames, SupportSubjects, ImageHost, SupportContacts, inputLengths };