import React, { useEffect, useState } from 'react';
import { useContext } from 'react';
import { IoRefresh } from 'react-icons/io5';
import { toast } from 'react-toastify';
import { MyContext } from '../../../App';
import { handleErrors, MyAxios, productNameById, Utils } from '../../../Utils/utils';
import AdminOrder from './AdminOrder';

const AdminOrders = () => {
    document.title = 'Orders || BetShop';
    const { user, setUser, setLoading } = useContext(MyContext);
    const [orders, setOrders] = useState([]);
    const [order, setOrder] = useState({});
    const [refresh, setRefresh] = useState(0);

    const [filterPage, setFilterPage] = useState(1);
    const [pages, setPages] = useState(1);

    useEffect(() => {
        (async () => {
            try {
                setLoading(true);
                const r = await MyAxios.get(`/orders/all?page=${filterPage}`, { jwt: user.jwt });
                handleErrors(r, true, setUser);
                if (!r?.ok) return;
                if (!r?.orders?.[0]) toast.success("No more orders found");
                if (r?.orders) setOrders(r.orders);
                if (r?.pages) setPages(r.pages);
            } finally { setLoading(false); }
        })();
    }, [user, setUser, setLoading, refresh, filterPage]);

    return (
        <div>


            {/* ORDER */}
            {order?.id && <AdminOrder values={{ order, setOrder, orders, setOrders }} />}
            {/* ORDER */}


            <h2 className="text-2xl flex gap-2 items-center justify-center md:justify-start">
                Orders <IoRefresh className='text-gray-500 cursor-pointer' onClick={() => setRefresh(refresh + 1)} />
            </h2>
            <div className='bg-white w-[100%] p-4 my-4 shadow rounded-lg'>
                <div className='overflow-x-auto'>
                    <table className='w-full text-left support-table'>
                        <thead>
                            <tr className='bg-gray-100'>
                                <th>ID</th>
                                <th>Date</th>
                                <th>Product</th>
                            </tr>
                        </thead>
                        <tbody>
                            {orders.map((order, i) => <tr className='border-b h-[50px]' key={i}>
                                <td className='min-w-[110px]'><button className='text-blue-500 hover:underline' onClick={() => setOrder(order)}>{order?.id?.slice(0, 10)}</button></td>
                                <td className='min-w-[110px]'>{Utils.dateFromTime(order?.created_at || 0)}</td>
                                <td className='min-w-[190px]'>{productNameById(order?.product_id)}</td>
                            </tr>)}
                        </tbody>
                    </table>
                </div>
                {pages > 1 && <div className='mt-5 text-center'>
                    <small>Page</small>
                    <select onChange={e => setFilterPage(Number(e.target.value))} className='border mx-2 w-16 cursor-pointer outline-none text-center'>
                        {[...Array(pages)].map((v, i) => <option value={i + 1} key={`page${i}`}>{i < 9 ? '0' + (i + 1) : (i + 1)}</option>)}
                    </select>
                    <small>of {pages < 9 ? '0' + pages : pages}</small>
                </div>}
            </div>

        </div>
    );
};


export default AdminOrders;