import React, { useContext, useState } from 'react';
import { MdClose, MdOutlineDashboard } from 'react-icons/md';
import { AiOutlineHome } from 'react-icons/ai';
import { GoSignOut } from 'react-icons/go';
import { MdAccountCircle } from 'react-icons/md';
import { RxHamburgerMenu } from 'react-icons/rx';
import { Link, useLocation } from "react-router-dom";
import { MyContext } from '../../../App';
import { useEffect } from 'react';
import { ImageHost } from '../../../app.config';
import { Utils } from '../../../Utils/utils';

const Header = ({ values: { menuExpanded, setMenuExpanded } }) => {
    const { user, uiText, setUser, setLoading } = useContext(MyContext);

    const [pathName, setPathName] = useState('/');
    const location = useLocation();
    useEffect(() => setPathName(location.pathname), [location]);

    return (
        <div className={`bg-white header shadow`}>
            <div className="navbar">
                <div className="flex-1">
                    <Link to='/' className="btn btn-ghost normal-case text-xl main-logo" style={{ padding: 0, color: 'white', textShadow: '0 0 4px black' }}>BET SHOP</Link>
                </div>
                <div className="flex-none">
                    {!user?.id ?
                        <Link to='/login' className='login-button duration-300 flex items-center gap-2 font-medium rounded-[20px] py-[8px] px-4 mx-1 bg-[#ecefed] hover:bg-gray-300'>
                            <MdAccountCircle className='text-2xl' />
                            {uiText.footer.account.account}
                        </Link>
                        :
                        <div className="navbar relative">
                            <img
                                onClick={() => document.querySelector('._nav-avatar').classList.toggle('active')}
                                className='w-9 bg-white rounded-full border-2 border-green-500 cursor-pointer _nav-avatar'
                                src={`${ImageHost}/${!user?.logo ? (user?.role === 'admin' ? 'admin' : 'avatar') + '.svg' : 'p' + user.logo}`} alt='Avatar'
                            />
                            <ul className="absolute hidden _nav-items py-4 shadow flex flex-col bg-base-100 right-11 top-6 rounded-xl"
                                onClick={() => document.querySelector('._nav-avatar').classList.remove('active')}>
                                <Link className='w-full flex flex-start gap-2 items-center hover:bg-gray-200 px-4 min-w-[150px] py-1'
                                    to='/'><AiOutlineHome /> {uiText.navMenus.home}
                                </Link>
                                <Link className='w-full flex flex-start gap-2 items-center hover:bg-gray-200 px-4 min-w-[150px] py-1'
                                    to={user?.role === 'admin' ? '/admin/orders' : 'account/orders'}><MdOutlineDashboard /> {uiText.navMenus.dash}
                                </Link>
                                <Link className='w-full flex flex-start gap-2 items-center hover:bg-gray-200 px-4 min-w-[150px] py-1'
                                    to='/' onClick={() => Utils.LogOut(setUser, setLoading)}><GoSignOut /> {uiText.navMenus.logout}
                                </Link>
                            </ul>
                        </div>
                    }
                    <div className={`md:hidden ml-1 sm:ml-3 cursor-pointer ${(pathName === '/' || pathName.startsWith('/orders/') || pathName.startsWith('/new/') || !user?.jwt) ? 'hidden' : ''}`}
                        onClick={() => setMenuExpanded(!menuExpanded)}>
                        <RxHamburgerMenu className={`text-3xl mr-3 ${menuExpanded && 'hidden'}`} />
                        <MdClose className={`text-3xl mr-3 ${!menuExpanded && 'hidden'}`} />
                    </div>
                </div>
            </div>
        </div >
    );
};

export default Header;