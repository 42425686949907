import React, { useContext, useState } from 'react';
import { useForm } from 'react-hook-form';
import { BiBookAdd } from 'react-icons/bi';
import { MdDelete } from 'react-icons/md';
import { toast } from 'react-toastify';
import { MyContext } from '../../../App';
import { PmNames } from '../../../app.config';
import HelpModal from '../../../Utils/HelpModal';
import { GetDbPms, handleErrors, MyAxios, productNameById } from '../../../Utils/utils';

const AdminProduct = ({ product, refresh, setRefresh }) => {
    const { register, handleSubmit, formState: { errors } } = useForm();
    const { user, setLoading, setUser } = useContext(MyContext);

    const { available, description, pms: dbPmss, id } = product;
    const [pms, setPms] = useState(dbPmss || []);
    const [helpModal, setHelpModal] = useState([]);
    const [dbPms, setDbPms] = useState([]);

    const onSubmit = async data => {
        try {
            setLoading(true);
            data.pms = pms;
            const r = await MyAxios.patch(`/products/${product.id}`, { jwt: user.jwt }, { product: data });
            if (r?.refresh) setRefresh(refresh + 1);
            handleErrors(r, true, setUser);
            if (!r?.ok) return;
            toast.success(r?.text || 'Success');
        }
        catch (e) { toast.error(e.message || e); }
        finally { setLoading(false); }
    }


    return (
        <div className='flex bg-white flex-col rounded-lg p-6 w-full md:w-[350px] max-w-[100%] shadow'>

            <div className={`${dbPms?.[0] ? 'flex flex-col' : 'hidden'} bg-white fixed w-full h-screen justify-center items-center top-0 left-0 bg-[#00000001] z-[9999] backdrop-blur-md`}>
                <div>
                    {dbPms.map((dbPm, i) => <div key={i + 'r'} className='bg-white min-w-[200px] p-2 my-1 rounded cursor-pointer shadow-lg hover:bg-green-100 duration-300'
                        onClick={() => { setPms([...pms, { available: false, rate: 0, account: dbPm.account, min: 0, max: 0 }]); setDbPms([]); }}>
                        {PmNames[dbPm.account]}
                    </div>)}
                    <div className='flex justify-center my-2 py-2 items-center gap-2 cursor-pointer bg-red-300 min-w-[200px] rounded shadow-lg'
                        onClick={() => setDbPms([])}> Close </div>
                </div>
            </div>

            {helpModal?.[0] && <HelpModal body={helpModal[1]} title={helpModal[0]} setHelp={setHelpModal} />}
            <h3 className="text-xl font-medium text-center mb-2">{productNameById(id)}</h3>
            <form className='mb-4'>


                <div className='flex justify-between'>
                    <p className='text-gray-500 text-sm'>Available:</p>
                    <input type="checkbox" defaultChecked={available} className="checkbox checkbox-accent" {...register('available')} />
                </div>

                <div className='mt-1'>
                    <div className='flex justify-between flex-col mt-2'>
                        <div className='flex justify-between items-center'>
                            <p className='text-gray-500 text-sm text-left'>Payment Methods</p>
                            <BiBookAdd className='cursor-pointer text-xl' onClick={() => GetDbPms(setLoading, user, setUser, pms, setDbPms)} />
                        </div>
                        <div className="flex flex-col pl-1 pt-2 gap-1 justify-start">
                            {pms.map((pm, i) => <PM key={i} pm={pm} pms={pms} setPms={setPms} />)}
                        </div>
                    </div>
                </div>

                <div className='flex justify-between flex-col mt-2'>
                    <p className='text-gray-500 text-sm text-left'>Description</p>
                    <textarea defaultValue={description} className="topup-box focus:border-lime-500 w-full" {...register('description',
                        { required: 'Required', minLength: { value: 5, message: 'Please enter more' }, maxLength: { value: 500, message: 'Too long' } })
                    } />
                    <p className='text-red-300 text-left text-xs'>{errors?.description?.message}</p>
                </div>

            </form>
            <button className={`btn btn-sm block mx-auto px-5 mt-auto`} onClick={handleSubmit(onSubmit)}>Save</button>
        </div>
    );
};

const PM = ({ pm, pms, setPms }) => {

    const Change = (name, value) => {
        const newPms = [];
        for (let i = 0, len = pms.length; i < len; i++) {
            const tempPms = pms[i];
            if (tempPms.account === pm.account) tempPms[name] = value;
            newPms.push(tempPms);
        }
        setPms(newPms);
    }

    return (
        <div className='flex justify-between scale-75'>
            <div className='flex flex-col justify-between'>
                <div className='flex items-center gap-1'>
                    <input type="checkbox" name='can_topup' className="checkbox" defaultChecked={pm?.available} onChange={e => Change('available', e.target.checked)} />
                    <p className='text-gray-500 text-sm pt-[1px]'>{pm.account}</p>
                </div>
                <div>
                    <div className='flex items-center justify-between'>
                        <p className='text-gray-500 text-sm mr-1'>Rate:</p>
                        <input type="text" className="my-pms-box focus:border-lime-500" defaultValue={pm?.rate || 0} onChange={e => Change('rate', e.target.value)} />
                    </div>
                </div>
            </div>
            <div className='flex flex-col gap-1'>
                <div className='flex items-center justify-between'>
                    <p className='text-gray-500 text-sm mr-1'>Min:</p>
                    <input type="text" className="my-pms-box focus:border-lime-500" defaultValue={pm?.min || 0} onChange={e => Change('min', e.target.value)} />
                </div>
                <div className='flex items-center justify-between'>
                    <p className='text-gray-500 text-sm mr-1'>Max:</p>
                    <input type="text" className="my-pms-box focus:border-lime-500" defaultValue={pm?.max || 0} onChange={e => Change('max', e.target.value)} />
                </div>
            </div>
            <div className='flex justify-center items-center'>
                <MdDelete className='text-red-400 text-3xl cursor-pointer' onClick={() => setPms(pms.filter(nPm => nPm.account !== pm.account))} />
            </div>
        </div>
    );
}

export default AdminProduct;