import React from 'react';
import { useContext } from 'react';
import { IoCloseCircleSharp } from 'react-icons/io5';
import { MdOutlineContentCopy } from 'react-icons/md';
import { toast } from 'react-toastify';
import { MyContext } from '../../../App';
import { coinTypeOfPm, copy, handleErrors, MyAxios, productNameById, Utils } from '../../../Utils/utils';

const AdminOrder = ({ values: { order, setOrder, orders, setOrders } }) => {
    const { user, setUser, recaptchaRef, setLoading } = useContext(MyContext);
    const lowId = order.product_id.toLowerCase();

    const UpdateOrderStatus = async data => {
        try {
            setLoading(true);
            const captcha = await recaptchaRef.current.executeAsync();
            const r = await MyAxios.patch(`/orders/${order.id}`, { jwt: user.jwt }, { captcha, order: data });
            handleErrors(r, true, setUser);
            if (!r?.ok) return;
            toast.success(`Order ${data.status === 'complete' ? 'Completed' : 'Cancelled'}`);
            setOrder({});
            const newOrders = orders.filter(newOrder => newOrder.id !== order.id);
            setOrders(newOrders);
        } finally { setLoading(false); }
    }

    return (
        <div className='fixed -top-10 left-0 w-full h-screen backdrop-blur z-[9] flex justify-center items-center'>
            <div className='w-[450px] max-w-[90%] bg-green-300 rounded-lg shadow-lg p-5 md:p-10 admin__order__details relative duration-900'>

                <IoCloseCircleSharp className='absolute text-4xl text-red-400 cursor-pointer  -top-4 -right-4' onClick={() => setOrder({})} />

                <div>
                    <p>Product</p>
                    <p>{productNameById(order.product_id)}</p>
                </div>
                <div>
                    <p>Date</p>
                    <p>{Utils.dateFromTime(order.created_at)}</p>
                </div>

                <hr className='my-2' />

                {
                    lowId.includes('1xbet_topup') ? <>
                        <div>
                            <p>Pay Method</p>
                            <p>{order.pm_method}</p>
                        </div>
                        <div>
                            <p>Pay Wallet</p>
                            <p>{order.pm_wallet}</p>
                        </div>
                        <div>
                            <p>Pay Amount</p>
                            <p>{order.pm_amount} {coinTypeOfPm(order.pm_method)}</p>
                        </div>
                        <div>
                            <p>Pay ID</p>
                            <p>{order.pm_id}</p>
                        </div>
                    </> : <>
                        <div>
                            <p>1xBet ID</p>
                            <p>{order.data.onex_id} <MdOutlineContentCopy className='cursor-pointer inline' onClick={() => copy(order.data.onex_id)} /></p>
                        </div>
                        <div>
                            <p>W-Code</p>
                            <p>{order.data.w_code} <MdOutlineContentCopy className='cursor-pointer inline' onClick={() => copy(order.data.w_code)} /></p>
                        </div>
                        <div>
                            <p>W-Amount</p>
                            <p>{order.pm_amount} {lowId.includes('usd') ? 'USD' : 'BDT'}</p>
                        </div>
                    </>
                }

                <hr className='my-2' />


                {
                    lowId.includes('1xbet_topup') ? <>
                        <div>
                            <p>1xBet ID</p>
                            <p>{order.data.onex_id} <MdOutlineContentCopy className='cursor-pointer inline' onClick={() => copy(order.data.onex_id)} /></p>
                        </div>
                        <div>
                            <p>Amount</p>
                            <p>{order.data.amount} {lowId.includes('usd') ? 'USD' : 'BDT'} <MdOutlineContentCopy className='cursor-pointer inline' onClick={() => copy(order.data.amount)} /></p>
                        </div>
                    </> : <>
                        <div>
                            <p>Method</p>
                            <p>{order.pm_method}</p>
                        </div>
                        <div>
                            <p>Wallet</p>
                            <p>{order.data.wallet} <MdOutlineContentCopy className='cursor-pointer inline' onClick={() => copy(order.data.wallet)} /></p>
                        </div>
                        <div>
                            <p>Amount</p>
                            <p>{order.data.amount} {order.pm_coin} <MdOutlineContentCopy className='cursor-pointer inline' onClick={() => copy(order.data.amount)} /></p>
                        </div>
                    </>
                }

                <div className='!justify-center !gap-4 mt-10'>
                    <button className='btn btn-sm w-28 btn-error shadow-md shadow-white hover:shadow-none' onClick={() => UpdateOrderStatus({ status: 'cancelled' })}>Cancel</button>
                    <button className='btn btn-sm w-28 btn-success shadow-md shadow-white hover:shadow-none' onClick={() => UpdateOrderStatus({ status: 'complete' })}>Complete</button>
                </div>

            </div>
        </div>
    );
};

export default AdminOrder;