import { useContext } from 'react';
import { Link } from 'react-router-dom';
import { MyContext } from '../../../App';
import { SupportContacts } from '../../../app.config';


const Footer = ({ setLangChangeCount }) => {

    const { uiText } = useContext(MyContext);

    return (
        <div className='mt-auto py-5 pt-10'>
            <hr className='w-full text-red-200 my-8' />
            <div className='flex justify-evenly items-start gap-6 flex-wrap'>

                <div className='flex flex-col gap-1 w-[140px] max-w-[100%]'>
                    <p className="font-semibold text-sm mb-1 text-gray-400">{uiText.footer.aboutUs.aboutUs}</p>
                    <Link to='/about-us' className='text-gray-500 relative duration-300 left-0 hover:left-2 hover:underline'>{uiText.footer.aboutUs.aboutUs}</Link>
                    <Link to='/privacy-policy' className='text-gray-500 relative duration-300 left-0 hover:left-2 hover:underline'>{uiText.footer.aboutUs.privacy}</Link>
                    <Link to='/tos' className='text-gray-500 relative duration-300 left-0 hover:left-2 hover:underline'>{uiText.footer.aboutUs.tos}</Link>
                </div>

                <div className='flex flex-col gap-1 w-[140px] max-w-[100%]'>
                    <p className="font-semibold text-sm mb-1 text-gray-400">{uiText.footer.account.account}</p>
                    <Link to='/login' className='text-gray-500 relative duration-300 left-0 hover:left-2 hover:underline'>{uiText.footer.account.login}</Link>
                    <Link to='/register' className='text-gray-500 relative duration-300 left-0 hover:left-2 hover:underline'>{uiText.footer.account.register}</Link>
                    <Link to='/account' className='text-gray-500 relative duration-300 left-0 hover:left-2 hover:underline'>{uiText.footer.account.dashboard}</Link>
                </div>

                <div className='flex flex-col gap-1 w-[210px] max-w-[100%]'>
                    <p className="font-semibold text-sm mb-1 text-gray-400">{uiText.footer.support.support}</p>
                    <a href={`tel:${SupportContacts.phone}`} className='text-gray-500 relative duration-300 left-0 hover:left-2 hover:underline'>
                        {uiText.footer.support.mobile}: {SupportContacts.phone}
                    </a>
                    <a href="mailto:support@betshop.pro" className='text-gray-500 relative duration-300 left-0 hover:left-2 hover:underline'>
                        {uiText.footer.support.email}: {SupportContacts.email}
                    </a>
                    <a href={`https://t.me/${SupportContacts.tg1.replace("@", "")}`} className='text-gray-500 relative duration-300 left-0 hover:left-2 hover:underline' target="_blank" rel="noreferrer">
                        {uiText.footer.support.telegram}: {SupportContacts.tg1}
                    </a>
                </div>
            </div>

            <div className='text-center mt-10'>
                <select className='outline-none cursor-pointer px-3 py-2 border' onChange={e => { localStorage.setItem("PREF_LANG", e.target.value); setLangChangeCount(Math.random()) }}
                    defaultValue={localStorage.getItem("PREF_LANG") || "EN"}>
                    <option value="EN">English</option>
                    <option value="BN">বাংলা</option>
                </select>
            </div>

            <p className='text-center mb-5 mt-10'>{uiText.footer.copyYears[new Date().getUTCFullYear()]} &copy; {uiText.footer.copyright}</p>
        </div>
    );
};

export default Footer;