import React, { useContext, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { MyContext } from '../../../../App';
import { MyAxios } from '../../../../Utils/utils';
import waitingSvg from './../../../../src/svg/order_status/waiting.svg';

const WaitingWithdraw = ({ order, setOrder }) => {
    const { id, last_time, data, pm_amount, pm_method, product_id } = order;
    const { uiText, setLoading, recaptchaRef } = useContext(MyContext);
    const navigate = useNavigate();

    const { register, formState: { errors }, handleSubmit } = useForm();
    const [timer, setTimer] = useState("0:00:00");
    const [leftTime, setLeftTime] = useState(100);

    /* TIMER */
    useEffect(() => {
        leftTime >= 0 && setTimeout(() => {
            const newLeftTime = (new Date(last_time * 1000)).getTime() - (new Date()).getTime();
            const date = new Date(newLeftTime);
            const hours = date.getUTCHours();
            const minutes = date.getUTCMinutes();
            const seconds = date.getUTCSeconds();
            const newTime = (hours > 9 ? hours : '0' + hours) + ':' + (minutes > 9 ? minutes : '0' + minutes) + ':' + (seconds > 9 ? seconds : '0' + seconds);
            setTimer(newTime);
            setLeftTime(newLeftTime);
        }, 1000);
    }, [leftTime, navigate, last_time]);

    /* UPDATE ORDER */
    const UpdateOrder = async data => {
        try {
            setLoading(true);
            const captcha = await recaptchaRef.current.executeAsync();
            const r = await MyAxios.patch('/orders/' + id, {}, { captcha, order: data });
            if (!r?.ok) return toast.error(r?.text || "Order data updating failed");
            if (r?.order) setOrder(r.order);
        }
        catch (e) { toast.error(e.message); }
        finally { setLoading(false); }
    }

    if (!data.amount) return;
    return (
        <div className='bg-white p-4 md:p-10 my-4 shadow rounded-lg'>
            <div className='flex justify-center mb-4'>
                <img src={waitingSvg} alt="" className='w-40' />
            </div>
            <div className='flex flex-col gap-2 mx-auto'>
                <p className='text-red-400 mb-4 text-sm'>{uiText.orders.waiting.warn}</p>

                <div>
                    <p className="mb-3">{uiText.orders.waiting.timeLeft} {timer}</p>
                    {uiText.orders.waiting.withdraw.youWillGet(data, pm_method, pm_amount, product_id)}
                </div>

                <form onSubmit={handleSubmit(UpdateOrder)}>
                    <p className="text-sm">{uiText.orders.waiting.withdraw.onexId}</p>
                    <input type="text" className='topup-box focus:border-lime-500 w-full' {...register('onex_id', {
                        required: 'Required',
                        pattern: { value: /^[0-9]{9}$/, message: uiText.orders.waiting.withdraw.onexIdErr }
                    })} />
                    <p className="text-xs text-red-400 mb-4">{errors?.onex_id?.message}</p>
                    <p className="text-sm mt-2">{uiText.orders.waiting.withdraw.wCode}</p>
                    <input type="text" className='topup-box focus:border-lime-500 w-full' {...register('w_code', {
                        required: 'Required',
                        pattern: { value: /^[0-9A-Za-z]{4}$/, message: uiText.orders.waiting.withdraw.wCodeErr }
                    })} />
                    <p className="text-xs text-red-400 mb-4">{errors?.w_code?.message}</p>
                    <button className="block mt-5 w-1/2 mx-auto submit__btn">{uiText.formFields.submit}</button>
                </form>
            </div>

            <button className="btn btn-error text-white mt-10 btn-sm block mx-auto normal-case" onClick={() => UpdateOrder({ cancel: true })}>{uiText.formFields.cancelOrder}</button>
        </div>
    );
};

export default WaitingWithdraw;