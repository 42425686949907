import React from 'react';
import { useContext } from 'react';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { MyContext } from '../../App';
import { handleErrors, MyAxios } from '../../Utils/utils';

const LoadUser = () => {
    const { user, setUser, setLoading, setUserLoading } = useContext(MyContext);
    const location = useLocation();

    useEffect(() => {
        (async () => {
            if (user?.id || location.pathname === '/') return;
            if (!localStorage.getItem('jwt')) return;
            try {
                setLoading(true);
                setUserLoading(true);
                const r = await MyAxios.get('/users/me', { jwt: localStorage.getItem('jwt') });
                handleErrors(r, true, setUser);
                if (r?.user) setUser(r.user);
            }
            finally {
                setLoading(false);
                setUserLoading(false);
            }
        })();
    }, [setLoading, setUserLoading, setUser, user, location]);

    return (
        <div className='h-[8vh]'></div>
    );
};

export default LoadUser;