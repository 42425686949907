import React, { useEffect, useState } from 'react';
import { IoCart } from 'react-icons/io5';
import { MdOutlineHelpOutline } from 'react-icons/md';
import HelpModal from '../../../../Utils/HelpModal';
import { coinTypeOfPm } from '../../../../Utils/utils';

const NewTopUp = ({ values: { uiText, handleSubmit, onSubmit, register, errors, pms, product } }) => {


    const [showAmt, setShowAmt] = useState("0");
    const [pm, setPm] = useState(pms[0]);
    const [help, setHelp] = useState([]);
    useEffect(() => setPm(pms[0]), [pms]);

    const changeAmount = () => {
        const amount = document.getElementById('amount')?.value || 0;
        const pm_method = document.getElementById('pm_method')?.value;
        const newPm = pms?.find(pm => pm.account === pm_method);
        setShowAmt((amount * (newPm.rate || 0)).toFixed(2));
        setPm(newPm);
    }

    return (
        <form onSubmit={handleSubmit(onSubmit)}>

            {/* Help Modal */}
            {help?.[0] && <HelpModal body={help[1]} title={help[0]} setHelp={setHelp} />}


            <div className="form-control">
                <label className="label pb-0 pl-0 items-center md:justify-start gap-2">
                    <span className="label-text">{uiText.orders.new.selectPmMethod}</span>
                    <MdOutlineHelpOutline className='_help-icon' onClick={() => setHelp([uiText.orders.new.selectPmMethod, uiText.orders.new.selectPmMethodHelp])} />
                </label>
                <select {...register("pm_method", { required: "Required" })} className='outline-none topup-box focus:border-lime-500 cursor-pointer' id="pm_method" onChange={changeAmount}>
                    {pms.map((pm, i) => <option key={i} value={pm.account}>{pm.account}</option>)}
                </select>
                <label className="label pl-1 pt-1"><span className="label-text-alt text-red-400">{errors?.pm_method?.message}</span></label>
            </div>

            <div className="form-control">
                <label className="label pb-0 pl-0 items-center md:justify-start gap-2">
                    <span className="label-text">{uiText.orders.new.payAmount} ({coinTypeOfPm(pm.account)})</span>
                    <MdOutlineHelpOutline className='_help-icon' onClick={() => setHelp([uiText.orders.new.payAmount, uiText.orders.new.payAmountHelp])} />
                </label>
                <input type="text" className="topup-box focus:border-lime-500" id="amount" onChange={changeAmount} onKeyUp={changeAmount}
                    {...register("amount", {
                        required: uiText.formErrors.payAmt.required,
                        min: { value: pm.min, message: uiText.formErrors.payAmt.min(pm.min) },
                        max: { value: pm.max, message: uiText.formErrors.payAmt.max(pm.max) },
                        pattern: { value: /^\d*\.?\d{0,2}$/, message: uiText.formErrors.payAmt.pattern }
                    })}
                />
                <label className="label pl-1 pt-1"><span className="label-text-alt text-red-400">{errors?.amount?.message}</span></label>
            </div>

            <div className="form-control">
                <label className="label pb-0 pl-0 items-center md:justify-start gap-2">
                    <span className="label-text">{uiText.orders.new.onexId}</span>
                    <MdOutlineHelpOutline className='_help-icon' onClick={() => setHelp([uiText.orders.new.onexId, uiText.orders.new.onexIdHelp])} />
                </label>
                <input type="text" className="topup-box focus:border-lime-500"
                    {...register("onex_id", {
                        required: uiText.formErrors.onexId.required,
                        minLength: { value: 9, message: uiText.formErrors.onexId.minLength },
                        maxLength: { value: 9, message: uiText.formErrors.onexId.maxLength },
                        pattern: { value: /^\d{9}$/, message: uiText.formErrors.onexId.pattern }
                    })}
                />
                <label className="label pl-1 pt-1"><span className="label-text-alt text-red-400">{errors?.onex_id?.message}</span></label>
            </div>
            <div className="form-control">
                <label className="label pb-0 pl-0 items-center md:justify-start gap-2">
                    <span className="label-text">{uiText.orders.new.contactEmail}</span>
                    <MdOutlineHelpOutline className='_help-icon' onClick={() => setHelp([uiText.orders.new.contactEmail, uiText.orders.new.contactEmailHelp])} />
                </label>
                <input type="email" className="topup-box focus:border-lime-500" placeholder='you@email.com' {...register("contact_email", {
                    required: uiText.formErrors.contactEmail.required,
                    minLength: uiText.formErrors.contactEmail.minLength,
                    maxLength: uiText.formErrors.contactEmail.maxLength
                })} />
                <label className="label pl-1 pt-1"><span className="label-text-alt text-red-400">{errors?.contact_email?.message}</span></label>
            </div>

            {uiText.orders.new.showAmt.topup(showAmt, product)}

            <button className='submit__btn w-40 mt-4 mx-auto md:mx-0'><IoCart className='text-2xl' /> {uiText.formFields.placeOrder}</button>
        </form>
    );
};

export default NewTopUp;