import React, { useContext } from 'react';
import { productNameById, Utils } from '../../../Utils/utils';

import { AiFillCheckCircle } from 'react-icons/ai';
import { BsHourglassSplit } from 'react-icons/bs';
import { IoIosTime } from 'react-icons/io';
import { MdCancel } from 'react-icons/md';
import { MyContext } from '../../../App';
import { ImageHost } from '../../../app.config';

const RecentOrders = ({ orders }) => {

    const { uiText } = useContext(MyContext);

    return (
        <div className='mt-[-40px] py-10 recent-orders bg-[#3988ff]'>
            <h2 className="text-3xl text-center pb-6">{uiText.home.recentOrders}</h2>
            <div className='m-4 gap-5 max-w-[1200px] mx-auto'>

                <div className='w-[90%] mx-auto overflow-x-auto rounded-2xl'>
                    <table className='orders-table bg-[#ffffff60] w-full rounded-2xl p-4'>
                        <thead>
                            <tr>
                                <th>{uiText.tableHeads.date}</th>
                                <th>{uiText.tableHeads.user}</th>
                                <th>{uiText.tableHeads.amount}</th>
                                <th>{uiText.tableHeads.product}</th>
                                <th>{uiText.tableHeads.status}</th>
                            </tr>
                        </thead>
                        <tbody>

                            {orders.map(({ product_id, created_at, pm_amount, status, userName, userLogo }, i) => <tr className='border-b border-white/800 h-[50px] hover:!bg-white/50' key={i}>
                                <td className='!min-w-[140px]'>{Utils.dateFromTime(created_at)}</td>
                                <td className='!min-w-[200px] flex items-center gap-2'>
                                    <img src={`${ImageHost}/${userLogo ? 'p' + userLogo : '/avatar.svg'}`} alt="" className='rounded-full w-8' />
                                    <p>{userName || 'anonymous'}</p>
                                </td>
                                <td className='!min-w-[100px]'>{parseFloat(pm_amount.toFixed(2))} {product_id.includes('usd') ? 'USD' : 'BDT'}</td>
                                <td className='!min-w-[180px]'>{productNameById(product_id)}</td>
                                <td className='!min-w-[140px]'>
                                    {status === 'waiting' && <IoIosTime className='inline mb-[3px] mr-2 bg-white rounded-full p-[1px] text-gray-500 text-xl' />}
                                    {status === 'pending' && <BsHourglassSplit className='inline mb-[3px] mr-2 bg-white rounded-full p-[1px] text-yellow-500 text-xl' />}
                                    {status === 'cancelled' && <MdCancel className='inline mb-[3px] mr-2 bg-white rounded-full p-[1px] text-red-400 text-xl' />}
                                    {status === 'complete' && <AiFillCheckCircle className='inline mb-[3px] mr-2 bg-white rounded-full p-[1px] text-green-400 text-xl' />}
                                    {uiText.orders.status[status]}
                                </td>
                            </tr>)}

                        </tbody>
                    </table>
                </div>

            </div>
        </div>
    );
};

export default RecentOrders;