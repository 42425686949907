import React from 'react';
import { Link } from 'react-router-dom';
import WaitingWithdraw from './WaitingWithdraw';

import pendingSvg from './../../../../src/svg/order_status/pending.svg';
import completeSvg from './../../../../src/svg/order_status/complete.svg';
import cancelledSvg from './../../../../src/svg/order_status/cancelled.svg';
import { coinTypeOfPm, productNameById } from '../../../../Utils/utils';
import { useContext } from 'react';
import { MyContext } from '../../../../App';

const OrderWIthdraw = ({ order, setOrder }) => {

    const { user, uiText } = useContext(MyContext);
    if (order.status === 'waiting') return <WaitingWithdraw order={order} setOrder={setOrder} />;

    return (
        <div className='bg-white my-4 shadow rounded-lg p-5 md:p-10 text-gray-600'>
            <div className='flex justify-center mb-4'>
                {order.status === 'pending' && <img src={pendingSvg} alt="" className='w-40' />}
                {order.status === 'complete' && <img src={completeSvg} alt="" className='w-40' />}
                {order.status === 'cancelled' && <img src={cancelledSvg} alt="" className='w-40' />}
            </div>
            <h3 className="mb-5 font-medium">{uiText.orders.order} #{order.id}</h3>
            <div className='flex flex-col gap-2'>
                <div className='flex'>
                    <p className='w-24 text-gray-500'>{uiText.otHeads.product}</p> <span className='font-medium'>: {productNameById(order.product_id)}</span>
                </div>
                <div className='flex'>
                    <p className='w-24 text-gray-500'>{uiText.otHeads.amount}</p> <span className='font-medium'>: {order.data.amount + ' ' + coinTypeOfPm(order.pm_method)}</span>
                </div>
                <div className='flex'>
                    <p className='w-24 text-gray-500'>{uiText.otHeads.method}</p> <span className='font-medium'>: {order.pm_method}</span>
                </div>
                <hr />
                <div className='flex'>
                    <p className='w-24 text-gray-500'>{uiText.otHeads.payAmount}</p>
                    <span className='font-medium'>: {order.pm_amount + ' ' + (order?.product_id?.includes('bdt') ? 'BDT' : 'USD')}
                    </span>
                </div>
                <div className='flex'>
                    <p className='w-24 text-gray-500'>{uiText.otHeads.payCode}</p> <span className='font-medium'>: {`${order.data.w_code} (${order.data.onex_id})`}</span>
                </div>
                <div className=''>
                    <p className='text-gray-500'>{uiText.otHeads.orderStatus}:</p> <span className='font-medium'>{uiText.otHeads.orderStatuses[order.status]}</span>
                </div>
            </div>

            {!user?.role && <Link to='/' className="btn btn-success normal-case block mx-auto flex w-40 mt-10">{uiText.navMenus.home}</Link>}
            {user?.role === 'admin' && <Link to='/admin/orders' className="btn btn-success normal-case block mx-auto flex w-40 mt-10">{uiText.navMenus.ordersList}</Link>}
            {user?.role === 'user' && <Link to='/account/orders' className="btn btn-success normal-case block mx-auto flex w-40 mt-10">{uiText.navMenus.ordersList}</Link>}
        </div>
    );
};

export default OrderWIthdraw;