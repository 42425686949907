import React, { useEffect, useState, useContext } from 'react';
import { useForm } from 'react-hook-form';
import { Link, useNavigate } from 'react-router-dom';
import { TogglePassword } from '../../Hooks/TogglePassword';
import { toast } from 'react-toastify';
import { FiAlertOctagon } from 'react-icons/fi';
import { MyContext } from '../../App';
import { handleErrors, MyAxios } from '../../Utils/utils';
import { inputLengths } from '../../app.config';

const Register = () => {
    document.title = 'Register || BetShop';
    const { type: passwordType, icon: passwordIcon, toggle: passwordToggle } = TogglePassword();
    const { register, formState: { errors }, handleSubmit } = useForm();
    const { user, uiText, setUser, setLoading, recaptchaRef } = useContext(MyContext);
    const [password, setPassword] = useState('');
    const navigate = useNavigate();

    // check if user already logged in
    useEffect(() => (user?.id) && navigate('/'), [user, navigate]);

    /* handle form submission */
    const onSubmit = async ({ name, email, password }) => {
        try {
            const captcha = await recaptchaRef.current.executeAsync();
            setLoading(true);
            const r = await MyAxios.post('/register', {}, { captcha, user: { name, email, password } });
            handleErrors(r, true, setUser);
            if (r?.user) setUser(r.user);
        }
        catch (e) { toast.error(e.message); }
        finally { setLoading(false) }
    }

    return (
        <div className='w-[500px] max-w-[90%] bg-white register-box mx-auto my-10 rounded-lg flex flex-col items-center py-10 px-5'>

            <p className="text-2xl font-medium">{uiText.register.register}</p>
            <small className='text-center'>{uiText.register.registerBody}</small>
            <form onSubmit={handleSubmit(onSubmit)} className='mt-6 w-[400px] max-w-[100%]'>

                {/* name */}
                <div className="form-control">
                    <label className="label pb-0 pl-0"><span className="label-text">{uiText.formFields.name}</span> </label>
                    <input type="text" className="topup-box focus:border-lime-500" {...register("name", {
                        required: uiText.formErrors.name.required,
                        minLength: { value: inputLengths.nameMin, message: uiText.formErrors.name.minLength },
                        maxLength: { value: inputLengths.nameMax, message: uiText.formErrors.name.maxLength },
                    })} />
                    <label className="label pl-1 pt-1"><span className="label-text-alt text-red-400">{errors.name?.message}</span></label>
                </div>

                {/* email */}
                <div className="form-control">
                    <label className="label pb-0 pl-0"><span className="label-text">{uiText.formFields.email}</span> </label>
                    <input type="email" className="topup-box focus:border-lime-500" {...register("email", {
                        required: uiText.formErrors.email.required,
                        minLength: { value: inputLengths.emailMin, message: uiText.formErrors.email.minLength },
                        maxLength: { value: inputLengths.emailMax, message: uiText.formErrors.email.maxLength },
                    })} />
                    <label className="label pl-1 pt-1"><span className="label-text-alt text-red-400">{errors.email?.message}</span></label>
                </div>

                {/* password */}
                <div className="form-control relative">
                    <label className="label pb-0 pl-0"><span className="label-text">{uiText.formFields.password}</span></label>
                    <input type={passwordType} className="topup-box focus:border-lime-500" {...register("password", {
                        onChange: e => setPassword(e.target.value),
                        required: uiText.formErrors.pass.required,
                        minLength: { value: inputLengths.passMin, message: uiText.formErrors.pass.minLength },
                        maxLength: { value: inputLengths.passMax, message: uiText.formErrors.pass.maxLength },
                    })} />
                    <label className="label pl-1 pt-1"><span className="label-text-alt text-red-400">{errors.password?.message}</span></label>
                    <div className="absolute top-[35px] h-[37px] right-0 cursor-pointer w-[50px] flex justify-center items-center" onClick={passwordToggle}>{passwordIcon}</div>
                </div>

                {/* confirm password */}
                <div className="form-control relative">
                    <label className="label pb-0 pl-0"><span className="label-text">{uiText.formFields.confirmPassword}</span></label>
                    <input type={passwordType} className="topup-box focus:border-lime-500" {...register("confirmPassword", {
                        required: uiText.formErrors.pass.confirmRequired,
                        validate: v => v !== password ? uiText.formErrors.pass.notMatch : null
                    })} />
                    <label className="label pl-1 pt-1"><span className="label-text-alt text-red-400">{errors?.confirmPassword?.message}</span></label>
                    <div className="absolute top-[35px] h-[37px] right-0 cursor-pointer w-[50px] flex justify-center items-center" onClick={passwordToggle}>{passwordIcon}</div>
                </div>

                {/* submit */}
                <div className="flex justify-center mt-5">
                    <input type="submit" className='btn btn-success mx-auto w-[200px] text-white' value={uiText.formFields.register} />
                </div>

                {/* options */}
                <div className='flex justify-center mt-5'>
                    {uiText.register.haveAccount} &nbsp; <Link to='/login' className='text-blue-500 underline'>{uiText.formFields.login}</Link>
                </div>
            </form>

            {/* ToS Notice */}
            <div className="mt-7 text-center"><FiAlertOctagon className='text-red-400 inline mb-1 mr-2' />{uiText.tosAgreement}</div>

        </div>
    );
};

export default Register;