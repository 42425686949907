import React, { useContext } from 'react';
import { AiFillCheckCircle } from 'react-icons/ai';
import { BsHourglassSplit } from 'react-icons/bs';
import { MdCancel } from 'react-icons/md';
import { IoIosTime } from 'react-icons/io';
import { Link, useNavigate } from 'react-router-dom';
import { handleErrors, MyAxios, productNameById, Utils } from '../../../Utils/utils';
import { useEffect } from 'react';
import { useState } from 'react';
import { MyContext } from '../../../App';
import { IoRefresh } from 'react-icons/io5';

const Orders = () => {
    document.title = 'Orders || BetShop';
    const { user, uiText, setUser, setLoading } = useContext(MyContext);
    const navigate = useNavigate();

    const [orders, setOrders] = useState([]);
    const [filterPage, setFilterPage] = useState(1);
    const [pages, setPages] = useState(1);
    const [refresh, setRefresh] = useState(0);
    const [showRefresh, setShowRefresh] = useState(true);

    // load orders list from db
    useEffect(() => {
        (async () => {
            try {
                setLoading(true);
                setShowRefresh(false);
                const r = await MyAxios.get(`/orders/all?page=${filterPage}`, { jwt: user.jwt });
                handleErrors(r, true, setUser);
                if (r?.orders) setOrders(r.orders);
                if (r?.pages) setPages(r.pages);
            }
            finally { setLoading(false); setTimeout(() => setShowRefresh(true), 1000); }
        })();
    }, [user, setLoading, filterPage, refresh, setUser]);

    return (
        <div>
            <h2 className="text-2xl flex gap-2 items-center justify-center md:justify-start">
                {uiText.orders.orders} <IoRefresh className={`text-gray-500 cursor-pointer ${showRefresh ? '' : 'hidden'}`} onClick={() => setRefresh(refresh + 1)} />
            </h2>
            <div className='bg-white w-[100%] p-4 my-4 shadow rounded-lg'>
                <h3 className="font-medium mb-5">
                    {uiText.orders.yourOrders}
                    <button className='btn btn-xs btn-success ml-3' onClick={() => navigate('/')}>+ {uiText.orders.newOrder}</button>
                </h3>
                <div className='overflow-x-auto'>
                    {/* orders list table */}
                    <table className='w-full text-left support-table'>
                        <thead>
                            <tr className='bg-gray-100'>
                                <th>{uiText.tableHeads.id}</th>
                                <th>{uiText.tableHeads.date}</th>
                                <th>{uiText.tableHeads.product}</th>
                                <th>{uiText.tableHeads.status}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {orders.map(({ id, created_at, product_id, status }, i) => <tr className='border-b h-[50px]' key={i}>
                                <td className='min-w-[110px]'><Link to={`/orders/${id}`} className='text-blue-500 hover:underline'>{id}</Link></td>
                                <td className='min-w-[110px]'>{Utils.dateFromTime(created_at || 0)}</td>
                                <td className='min-w-[180px]'>{productNameById(product_id)}</td>
                                <td className='min-w-[120px]'>
                                    {status === 'waiting' && <IoIosTime className='inline mb-[3px] mr-1 text-gray-500 text-xl' />}
                                    {status === 'pending' && <BsHourglassSplit className='inline mb-[3px] mr-1 text-yellow-500 text-xl' />}
                                    {status === 'cancelled' && <MdCancel className='inline mb-[3px] mr-1 text-red-400 text-xl' />}
                                    {status === 'complete' && <AiFillCheckCircle className='inline mb-[3px] mr-1 text-green-400 text-xl' />}
                                    {uiText.orders.status[status]}
                                </td>
                            </tr>)}
                        </tbody>
                    </table>
                </div>

                {/* pagination */}
                {pages > 1 && <div className='mt-5 text-center'>
                    <small>{uiText.pagination.page}</small>
                    <select onChange={e => setFilterPage(Number(e.target.value))} className='border mx-2 w-16 cursor-pointer outline-none text-center'>
                        {[...Array(pages)].map((v, i) => <option value={i + 1} key={`page${i}`}>{i < 9 ? '0' + (i + 1) : (i + 1)}</option>)}
                    </select>
                    <small>{uiText.pagination.of} {pages < 9 ? '0' + pages : pages}</small>
                </div>}
            </div>

        </div>
    );
};

export default Orders;