import React, { useEffect, useState } from 'react';
import { useContext } from 'react';
import { IoRefresh } from 'react-icons/io5';
import { MdOutlineContentCopy } from 'react-icons/md';
import { MyContext } from '../../../App';
import { copy, handleErrors, MyAxios, Utils } from '../../../Utils/utils';
import AdminUser from './AdminUser';

const AdminUsers = () => {
    document.title = 'Users || BetShop';
    const { user, setUser, setLoading } = useContext(MyContext);
    const [users, setUsers] = useState([]);
    const [dbUser, setDbUser] = useState({});
    const [refresh, setRefresh] = useState(0);

    const [filterPage, setFilterPage] = useState(1);
    const [pages, setPages] = useState(1);

    useEffect(() => {
        (async () => {
            try {
                setLoading(true);
                const r = await MyAxios.get(`/users/all?page=${filterPage}`, { jwt: user.jwt });
                handleErrors(r, true, setUser);
                if (r?.users) setUsers(r.users);
                if (r?.pages) setPages(r.pages);
            } finally { setLoading(false); }
        })();
    }, [user, setUser, setLoading, refresh, filterPage]);

    return (
        <div className='max-w-[100%]'>

            {/* User Details */}
            {dbUser?.num && <AdminUser values={{ users, setUsers, dbUser, setDbUser }} />}

            <h2 className="text-2xl flex gap-2 items-center justify-center md:justify-start">
                Users <IoRefresh className='text-gray-500 cursor-pointer' onClick={() => setRefresh(refresh + 1)} />
            </h2>
            <div className='bg-white w-[100%] p-4 my-4 shadow rounded-lg'>
                <div className='overflow-x-auto max-w-[100%]'>
                    <table className='w-full text-left support-table'>
                        <thead>
                            <tr className='bg-gray-100'>
                                <th>Date</th>
                                <th>Orders</th>
                                <th>Name</th>
                                <th>Email</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {users.map((user, i) => <tr className='border-b h-[50px]' key={i}>
                                <td className='min-w-[110px]'>{Utils.dateFromTime(user?.created_at || 0)}</td>
                                <td className='min-w-[65px] text-center lg:text-left lg:pl-1'>{user?.orders}</td>
                                <td className='min-w-[150px]'>{user?.name}</td>
                                <td className='min-w-[180px]'>
                                    {user?.email} <MdOutlineContentCopy className='text-gray-400 inline ml-1 cursor-pointer' onClick={() => copy(user?.email)} />
                                </td>
                                <td className='min-w-[110px]'><button className='text-blue-500 hover:underline' onClick={() => setDbUser(user)}>Details</button></td>
                            </tr>)}
                        </tbody>
                    </table>
                </div>
                {pages > 1 && <div className='mt-5 text-center'>
                    <small>Page</small>
                    <select onChange={e => setFilterPage(Number(e.target.value))} className='border mx-2 w-16 cursor-pointer outline-none text-center'>
                        {[...Array(pages)].map((v, i) => <option value={i + 1} key={`page${i}`}>{i < 9 ? '0' + (i + 1) : (i + 1)}</option>)}
                    </select>
                    <small>of {pages < 9 ? '0' + pages : pages}</small>
                </div>}
            </div>

        </div>
    );
};


export default AdminUsers;