import React, { useContext, useEffect, useState } from 'react';
import { IoRefresh } from 'react-icons/io5';
import { MyContext } from '../../../App';
import { handleErrors, MyAxios } from '../../../Utils/utils';
import PayMethod from './PayMethod';

const Paymethods = () => {
    document.title = 'Payment methods || BetShop';
    const { user, setUser, setLoading } = useContext(MyContext);
    const [pms, setPms] = useState([]);
    const [refresh, setRefresh] = useState(0);

    useEffect(() => {
        (async () => {
            try {
                setLoading(true);
                setPms([]);
                const r = await MyAxios.get('/pms/all', { jwt: user.jwt });
                handleErrors(r, true, setUser)
                if (r?.pms) setPms(r.pms);
            }
            finally { setLoading(false) }
        })();

    }, [setLoading, user, setUser, refresh]);
    return (
        <div>
            <h2 className="text-2xl flex gap-2 items-center justify-center md:justify-start">
                Pay Methods <IoRefresh className='text-gray-500 cursor-pointer' onClick={() => setRefresh(refresh + 1)} />
            </h2>
            <div className='flex gap-5 mt-5 flex-wrap justify-start'>
                {pms.map((pm, i) => <PayMethod pm={pm} key={`${i}-pm`} refresh={refresh} setRefresh={setRefresh} />)}
            </div>
        </div>
    );
};

export default Paymethods;