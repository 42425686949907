import React, { useContext, useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { FcComments } from 'react-icons/fc';
import { AiOutlineFileDone } from 'react-icons/ai';
import { handleErrors, MyAxios, Utils } from '../../../Utils/utils';
import { MyContext } from '../../../App';
import { IoRefresh } from 'react-icons/io5';

const Supports = () => {
    document.title = 'Support tickets || BetShop';
    const { user, uiText, setUser, setLoading } = useContext(MyContext);
    const navigate = useNavigate();
    const [supports, setSupports] = useState([]);
    const [pages, setPages] = useState(1);
    const [filterPage, setFilterPage] = useState(1);
    const [refresh, setRefresh] = useState(0);
    const [showRefresh, setShowRefresh] = useState(true);

    // load support list from db
    useEffect(() => {
        (async () => {
            try {
                setLoading(true);
                setShowRefresh(false);
                const r = await MyAxios.get('/supports/all?page=' + filterPage, { jwt: user.jwt });
                handleErrors(r, true, setUser);
                if (r?.supports) setSupports(r.supports);
                if (r?.pages) setPages(r.pages);
            }
            finally { setLoading(false); setTimeout(() => setShowRefresh(true), 1000); }
        })();
    }, [setLoading, user, setUser, refresh, filterPage]);

    return (
        <div>
            <h2 className="text-2xl flex gap-2 items-center justify-center md:justify-start">
                {uiText.supports.supportCenter} <IoRefresh className={`text-gray-500 cursor-pointer ${showRefresh ? '' : 'hidden'}`} onClick={() => setRefresh(refresh + 1)} />
            </h2>
            <div className='bg-white p-4 my-4 shadow rounded-lg'>

                <h3 className="font-medium mb-5">
                    {uiText.supports.yourSupports}
                    <button className='btn btn-xs btn-success ml-3' onClick={() => navigate('/account/supports/new')}>+ {uiText.supports.new}</button>
                </h3>

                <div className='overflow-x-auto'>

                    {/* support list table */}
                    <table className='w-full text-left support-table'>
                        <thead>
                            <tr className='bg-gray-100'>
                                <th>{uiText.tableHeads.id}</th>
                                <th>{uiText.tableHeads.date}</th>
                                <th>{uiText.tableHeads.message}</th>
                                <th>{uiText.tableHeads.status}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {supports.map((ticket, i) => <tr className='border-b h-[50px]' key={i}>
                                <td className='min-w-[110px]'><Link to={`/account/supports/${ticket?.id}`} className='text-blue-500 hover:underline'>{ticket?.id}</Link></td>
                                <td className='min-w-[160px]'>{Utils.dateFromTime(ticket?.created_at || 0)}</td>
                                <td className='min-w-[300px]'>{ticket?.last_text}</td>
                                <td className='min-w-[90px]'>
                                    {ticket?.status === 'open' && <FcComments className='inline mb-[3px] mr-1 text-red-500 text-xl' />}
                                    {ticket?.status === 'closed' && <AiOutlineFileDone className='inline mb-[3px] mr-1 text-yellow-500 text-xl' />}
                                    {ticket?.status === 'open' ? 'Open' : 'Closed'}
                                </td>
                            </tr>)}
                        </tbody>
                    </table>

                </div>

                {/* pagination */}
                {pages > 1 && <div className='mt-5 text-center'>
                    <small>{uiText.pagination.page}</small>
                    <select onChange={e => setFilterPage(Number(e.target.value))} className='border mx-2 w-16 cursor-pointer outline-none text-center'>
                        {[...Array(pages)].map((v, i) => <option value={i + 1} key={`page${i}`}>{i < 9 ? '0' + (i + 1) : (i + 1)}</option>)}
                    </select>
                    <small>{uiText.pagination.of} {pages < 9 ? '0' + pages : pages}</small>
                </div>}
            </div>
        </div>
    );
};

export default Supports;