import React, { useEffect, useContext } from 'react';
import { useForm } from 'react-hook-form';
import { Link, useNavigate } from 'react-router-dom';
import { TogglePassword } from '../../Hooks/TogglePassword';
import { toast } from 'react-toastify';
import { FiAlertOctagon } from 'react-icons/fi';
import { handleErrors, MyAxios } from '../../Utils/utils';
import { MyContext } from '../../App';
import { inputLengths } from '../../app.config';

const Login = () => {
    document.title = 'Login || BetShop';
    const { register, formState: { errors }, handleSubmit } = useForm();
    const { user, uiText, setLoading, setUser, setUserLoading, recaptchaRef } = useContext(MyContext);
    const navigate = useNavigate();

    // check if user already logged in
    useEffect(() => (user?.id) && navigate('/'), [user, navigate]);

    /* handle form submission */
    const onSubmit = async ({ email, password }) => {
        try {
            const captcha = await recaptchaRef.current.executeAsync();
            setLoading(true);
            setUserLoading(true);
            const r = await MyAxios.post('/login', {}, { captcha, user: { email, password } });
            handleErrors(r, true, setUser);
            if (r?.user) {
                setUser(r.user);
                if (r.user.jwt) localStorage.setItem('jwt', r.user.jwt);
                navigate(r.user?.role === 'admin' ? '/admin/orders' : '/account/orders');
            }

        }
        catch (e) { toast.error(e.message); }
        finally {
            setLoading(false);
            setUserLoading(false);
        }
    }

    const { type: passwordType, icon: passwordIcon, toggle: passwordToggle } = TogglePassword();

    return (
        <div className='w-[500px] max-w-[90%] bg-white register-box mx-auto my-10 rounded-lg flex flex-col items-center py-10 px-5'>

            <p className="text-2xl font-medium">{uiText.login.login}</p>
            <small className='text-center'>{uiText.login.loginBody}</small>
            <form onSubmit={handleSubmit(onSubmit)} className='mt-10 mb-2 w-[400px] max-w-[100%]'>

                {/* email */}
                <div className="form-control">
                    <label className="label pb-1 pl-0"><span className="label-text">{uiText.formFields.email}</span></label>
                    <input type="email" className="topup-box focus:border-lime-500" {...register("email", {
                        required: uiText.formErrors.email.required,
                        minLength: { value: inputLengths.emailMin, message: uiText.formErrors.email.minLength },
                        maxLength: { value: inputLengths.emailMax, message: uiText.formErrors.email.maxLength },
                    })} />
                    <label className="label pl-1 pt-1"><span className="label-text-alt text-red-400">{errors.email?.message}</span></label>
                </div>

                {/* password */}
                <div className="form-control relative">
                    <label className="label pb-1 pl-0"><span className="label-text">{uiText.formFields.password}</span></label>
                    <input type={passwordType} className="topup-box focus:border-lime-500"
                        {...register("password", {
                            required: uiText.formErrors.pass.required,
                            minLength: { value: inputLengths.passMin, message: uiText.formErrors.pass.minLength },
                            maxLength: { value: inputLengths.passMax, message: uiText.formErrors.pass.maxLength },
                        })}
                    />
                    <label className="label pl-1 pt-1"><span className="label-text-alt text-red-400">{errors.password?.message}</span></label>
                    <div className="absolute top-[35px] h-[43px] right-0 cursor-pointer w-[50px] flex justify-center items-center" onClick={passwordToggle}>{passwordIcon}</div>
                </div>

                {/* submit */}
                <div className="flex justify-center mt-5">
                    <input type="submit" id="submit" className='btn btn-success mx-auto w-[200px] text-white' value={uiText.formFields.login} />
                </div>

                {/* options */}
                <div className='flex flex-col items-center mt-5'>
                    <div>{uiText.login.newHere} &nbsp; <Link to='/register' className='text-blue-500 underline'>{uiText.formFields.register}</Link></div>
                    <div>{uiText.login.forgotPassword} &nbsp; <Link to='/recovery' className='text-blue-500 underline'>{uiText.formFields.reset}</Link></div>
                </div>
            </form>

            {/* ToS Notice */}
            <div className="mt-7 text-center"><FiAlertOctagon className='text-red-400 inline mb-1 mr-2' />{uiText.tosAgreement}</div>

        </div>
    );
};

export default Login;