import { inputLengths } from "../../app.config"
import { coinTypeOfPm } from "../utils";

const nums = { '0': '০', '1': '১', '2': '২', '3': '৩', '4': '৪', '5': '৫', '6': '৬', '7': '৭', '8': '৮', '9': '৯', '.': '.', ',': ',' };

const NewBnTexts = {
    home: {
        home: "হোমপেজ",
        buySell: "কেনা / বেচা",
        buySellBody: "আপনার 1xBet অ্যাকাউন্ট -এ টপআপ বা উইথড্র এবং আরও অনেক কিছু",
        browseProducts: "প্রোডাক্ট ব্রাউজ করুন",
        topProductsToday: "আজকের শীর্ষ প্রোডাক্টগুলি",
        howItWorks: {
            howItWorks: "কিভাবে কাজ করে!",
            oneHead: "১. অ্যাকাউন্ট তৈরি করুন",
            oneBody: "আপনার সমস্ত ডাটা সুরক্ষিত সার্ভারে সংরক্ষণ করা হয়",
            twoHead: "২. প্রোডাক্ট অর্ডার করুন",
            twoBody: "হয় আপনি টপআপ বা উইথড্র করতে চান, শুধু একটি অর্ডার দিন",
            threeHead: "৩. মিনিটের মধ্যে পেমেন্ট পান",
            threeBody: "অর্ডার করার কয়েক মিনিটের মধ্যে অর্ডার সম্পন্ন করা হয়!",
        },
        whyUs: {
            whyUs: "কেন আমাদের বেছে নিবেন?",
            whyUs1: "নিরাপদ এবং নির্ভরযোগ্য",
            whyUs2: "বাজারের সেরা দর",
            whyUs3: "৫-১০ মিনিটের লেনদেন",
            whyUs4: "দ্রুত অ্যাডমিন সহায়তা",
        },
        recentOrders: "সাম্প্রতিক অর্ডার",
    },
    footer: {
        aboutUs: {
            aboutUs: "আমাদের সম্পর্কে",
            privacy: "গোপনীয়তা নীতি",
            tos: "পরিষেবার শর্তাবলী",
        },
        account: {
            account: "অ্যাকাউন্ট",
            login: "লগইন",
            register: "রেজিস্টার",
            dashboard: "ড্যাশবোর্ড",
        },
        support: {
            support: "সহায়তা",
            mobile: "মোবাইল",
            email: "ইমেইল",
            telegram: "টেলিগ্রাম",
        },
        copyright: "সমস্ত অধিকার সংরক্ষিত",
        copyYears: { '2023': '২০২৩', '2024': '২০২৪', '2025': '২০২৫', }
    },
    orders: {
        order: "অর্ডার",
        orders: "অর্ডার সমূহ",
        orderDetails: "অর্ডার বিস্তারিত",
        yourOrders: "আপনার অর্ডার সমূহ",
        newOrder: "নতুন অর্ডার",
        new: {
            selectPmMethod: "পেমেন্ট মেথড",
            selectPmMethodHelp: "পেমেন্ট মেথড নির্বাচন করুন যার মাধ্যমে আপনি আমাদের পেমেন্ট করবেন",
            selectPmMethodHelpP: "পেমেন্ট মেথড নির্বাচন করুন যার মাধ্যমে আমরা আপনাকে পেমেন্ট করব",
            payAmount: "পেমেন্ট অ্যামাউন্ট",
            payAmountHelp: "উপরে বেছে নেওয়া পেমেন্ট মেথডের মাধ্যমে আমাদের যে পরিমাণ পেমেন্ট করবেন তা লিখুন",
            wAmount: "উইথড্র অ্যামাউন্ট",
            wAmountHelp: "আপনার 1xBet অ্যাকাউন্ট থেকে যে পরিমাণ উইথড্র করবেন তা লিখুন",
            onexId: "1xBet অ্যাকাউন্ট আইডি",
            onexIdHelp: "আপনার 1xBet অ্যাকাউন্ট আইডি লিখুন",
            wWallet: "ওয়ালেট",
            wWalletHelp: "উপরে বেছে নেওয়া পেমেন্ট মেথডের জন্য আপনার ওয়ালেট ঠিকানা লিখুন",
            contactEmail: "যোগাযোগের ই-মেইল",
            contactEmailHelp: "আপনার ইমেল অ্যাড্রেস লিখুন, আমরা কোন সমস্যার ক্ষেত্রে আপনার সাথে যোগাযোগ করতে এই ইমেল ব্যবহার করব",
            showAmt: {
                topup: (showAmt, product) => <p className='text-left text-xs mt-2'>
                    আপনি প্রায় <span className='font-bold text-green-600'>{showAmt} {product.id.includes('bdt') ? 'BDT' : 'USD'}</span> আপনার 1xBet অ্যাকাউন্ট-এ পাবেন<br />
                    এই অ্যামাউন্ট <span className='text-red-400'>পরিবর্তন</span> হতে পারে, অর্ডার কনফার্ম পেজ-এ চূড়ান্ত অ্যামাউন্ট দেখানো হবে
                </p>,
                withdraw: (showAmt, product, pm) => <p className='text-left text-xs mt-2'>
                    আপনি প্রায় <span className='font-bold text-green-600'>{showAmt} {coinTypeOfPm(pm.account)}</span> আপনার {pm.account} ওয়ালেট-এ পাবেন<br />
                    এই অ্যামাউন্ট <span className='text-red-400'>পরিবর্তন</span> হতে পারে, অর্ডার কনফার্ম পেজ-এ চূড়ান্ত অ্যামাউন্ট দেখানো হবে
                </p>
            }
        },
        waiting: {
            note: "বিঃদ্রঃ:",
            warn: "সাবধানে অর্ডার বিবরণ চেক করুন। সবকিছু ঠিকঠাক থাকলে এগিয়ে যান, অন্যথায় অর্ডার বাতিল করুন",
            timeLeft: "সময় বাকিঃ",
            topup: {
                youWillGet: (data, product_id) => <p className='mb-3'>
                    আপনি <span className='font-bold'>{data.amount} {product_id.includes('USD') ? 'USD' : 'BDT'}</span>
                    &nbsp;আপনার 1xBet অ্যাকাউন্টঃ <span className="font-bold">{data.onex_id}</span> -এ পাবেন
                </p>,
                pleasePay: (pm_amount, pm_coin, pm_method) => <><span className='font-bold text-green-500'>{pm_amount} {pm_coin}</span> আমাদের {pm_method} ওয়ালেটে পে করুনঃ</>,
                txId: {
                    txId: account => `${account} ট্রানজেকশন আইডি`,
                    required: `ট্রানজেকশন আইডি বাধ্যতামূলক`,
                    err: `ভুল ট্রানজেকশন আইডি`
                }
            },
            withdraw: {
                onexId: "আপনার 1xBet অ্যাকাউন্ট আইডি",
                onexIdErr: "ভুল 1xBet অ্যাকাউন্ট আইডি",
                wCode: "1xBet উইথড্র কোড",
                wCodeErr: "ভুল উইথড্র কোড",
                youWillGet: (data, pm_method, pm_amount, product_id) => <>
                    <p className='mb-3'>
                        আপনি <span className='font-bold'>{data.amount} {coinTypeOfPm(pm_method)}</span> আপনার {pm_method} ওয়ালেট: <span className="font-bold">{data.wallet}</span>-এ পাবেন
                    </p>
                    <div>
                        আপনার 1xBet অ্যাকাউন্ট থেকে একটি উইথড্র রিকুয়েষ্ট তৈরি করুন<br />
                        Amount: <span className='font-medium text-green-500'>{pm_amount} {product_id.includes('usd') ? 'USD' : 'BDT'}<br /></span>
                        City: <span className='font-medium text-green-500'>Bhaluka (Mymensingh)<br /></span>
                        Street: <span className='font-medium text-green-500'>Josor Khagrachhari Bandorbon65</span>
                    </div>
                </>
            }
        },
        status: {
            waiting: 'অপেক্ষমান',
            timeout: 'সময়োত্তীর্ণ',
            pending: 'প্রসেসিং',
            cancelled: 'বাতিল',
            complete: 'সম্পন্ন',
        }
    },
    otHeads: {
        product: "প্রোডাক্ট",
        amount: "অ্যামাউন্ট",
        payMethod: "পে মেথড",
        method: "মেথড",
        payAmount: "পে অ্যামাউন্ট",
        payId: "পে আইডি",
        payCode: "Pay Code",
        orderStatus: "অর্ডারের অবস্থাঃ",
        orderStatuses: {
            waiting: "ইউজার পেমেন্ট করার জন্য অপেক্ষা করা হচ্ছে",
            pending: "অ্যাডমিন শীঘ্রই অর্ডারটি সম্পূর্ণ করবে",
            timedout: "অর্ডারের সময় শেষ। আপনি যদি ইতিমধ্যেই পেমেন্ট করে থাকেন অনুগ্রহ করে পৃষ্ঠার নীচে প্রদত্ত সহায়তা টিকেট বা ইমেলের মাধ্যমে অ্যাডমিনের সাথে যোগাযোগ করুন",
            user_cancelled: "অর্ডার ইউজার দ্বারা বাতিল করা হয়েছে",
            admin_cancelled: "অর্ডার অ্যাডমিন দ্বারা বাতিল করা হয়েছে",
            cancelled: "অর্ডার বাতিল করা হয়েছে। আপনি যদি ইতিমধ্যেই পেমেন্ট করে থাকেন অনুগ্রহ করে পৃষ্ঠার নীচে প্রদত্ত সহায়তা টিকেট বা ইমেলের মাধ্যমে অ্যাডমিনের সাথে যোগাযোগ করুন",
            complete: "অর্ডারটি সফলভাবে সম্পূর্ণ হয়েছে",
        }
    },
    tableHeads: {
        id: "আইডি",
        date: "সময়",
        amount: "পরিমাণ",
        product: "প্রোডাক্ট",
        message: "বার্তা",
        status: "অবস্থা",
        user: "ইউজার",
    },
    formFields: {
        save: "সংরক্ষণ",
        name: "নাম",
        email: "ইমেইল",
        password: "পাসওয়ার্ড",
        newPassword: "নতুন পাসওয়ার্ড",
        currentPassword: "বর্তমান পাসওয়ার্ড",
        confirmPassword: "পাসওয়ার্ড নিশ্চিত করুন",
        submit: "জমা দিন",
        reset: "রিসেট করুন",
        login: "লগইন",
        register: "রেজিস্টার",
        resend: "পুনরায় পাঠান",
        send: "পাঠান",
        goBack: "পেছনে ফিরুন",
        change: "পরিবর্তন",
        placeOrder: "অর্ডার করুন",
        cancelOrder: "বাতিল করুন"
    },
    formErrors: {
        code: {
            required: "যাচাই কোড বাধ্যতামূলক",
            minLength: `সর্বনিম্ন ${inputLengths.passVerifyCodeMin.toString().split("").map(s => nums[s]).join("")} অক্ষর`,
            maxLength: `সর্বোচ্চ ${inputLengths.passVerifyCodeMax.toString().split("").map(s => nums[s]).join("")} অক্ষর`,
            regex: `অবৈধ কোড সজ্জা`,
        },
        name: {
            required: "নাম বাধ্যতামূলক",
            minLength: `সর্বনিম্ন ${inputLengths.nameMin.toString().split("").map(s => nums[s]).join("")} অক্ষর`,
            maxLength: `সর্বোচ্চ ${inputLengths.nameMax.toString().split("").map(s => nums[s]).join("")} অক্ষর`,
        },
        email: {
            required: "ইমেইল বাধ্যতামূলক",
            minLength: `সর্বনিম্ন ${inputLengths.emailMin.toString().split("").map(s => nums[s]).join("")} অক্ষর`,
            maxLength: `সর্বোচ্চ ${inputLengths.emailMax.toString().split("").map(s => nums[s]).join("")} অক্ষর`,
        },
        pass: {
            notMatch: "পাসওয়ার্ড মিলছে না",
            required: "পাসওয়ার্ড বাধ্যতামূলক",
            newRequired: "নতুন পাসওয়ার্ড বাধ্যতামূলক",
            confirmRequired: "যাচাইকরণ পাসওয়ার্ড বাধ্যতামূলক",
            currentRequired: "বর্তমান পাসওয়ার্ড বাধ্যতামূলক",
            minLength: `সর্বনিম্ন ${inputLengths.passMin.toString().split("").map(s => nums[s]).join("")} অক্ষর`,
            maxLength: `সর্বোচ্চ ${inputLengths.passMax.toString().split("").map(s => nums[s]).join("")} অক্ষর`,
        },
        msg: {
            required: "বার্তা বাধ্যতামূলক",
            minLength: `একটি দীর্ঘ বার্তা লিখুন`,
            maxLength: `সর্বোচ্চ ৫০০ অক্ষর`,
        },
        payAmt: {
            required: "পেমেন্ট অ্যামাউন্ট বাধ্যতামূলক",
            min: min => `সর্বনিম্ন ${min.toString().split("").map(s => nums[s]).join("")}`,
            max: max => `সর্বোচ্চ ${max.toString().split("").map(s => nums[s]).join("")}`,
            pattern: `ভুল অ্যামাউন্ট`,
        },
        onexId: {
            required: "1xBet আইডি বাধ্যতামূলক",
            minLength: `সর্বনিম্ন ৯ ডিজিট`,
            maxLength: `সর্বোচ্চ ৯ ডিজিট`,
            pattern: `শুধুমাত্র নাম্বার অনুমোদিত`,
        },
        contactEmail: {
            required: "Contact email required",
            minLength: `সর্বনিম্ন 5 অক্ষর`,
            maxLength: `সর্বোচ্চ 50 অক্ষর`
        }
    },
    login: {
        login: "লগইন",
        loginBody: "আপনার অ্যাকাউন্ট এ লগইন করুন",
        newHere: "এখানে নতুন?",
        forgotPassword: "পাসওয়ার্ড ভুলে গেছেন?",
    },
    recovery: {
        resendIn: "আবার পাঠানঃ",
        recovery: "পাসওয়ার্ড পুনরুদ্ধার",
        recoveryBody: "অ্যাকাউন্ট পাসওয়ার্ড পুনরুদ্ধার করুন",
        goBack: "ফিরে যান",
        confirmYourEmail: "ইমেইল নিশ্চিত করুন",
        codeFromMail: "ইমেল থেকে যাচাইকরণ কোড",
        didntReceivedMail: "ইমেইল পাননি?",
    },
    register: {
        register: "অ্যাকাউন্ট তৈরি করুন",
        registerBody: "পণ্য এবং আরও অনেক কিছু কিনতে নতুন অ্যাকাউন্ট করুন!",
        haveAccount: "অ্যাকাউন্ট আছে?",
    },
    supports: {
        new: "নতুন",
        close: "টিকিট বন্ধ করুন",
        ticket: "টিকিট",
        support: "সহায়তা",
        supportCenter: "সহায়তা কেন্দ্র",
        yourSupports: "আপনার সহায়তা টিকিট সমূহ",
        enterYourMsg: "আপনার বার্তা লিখুন",
    },
    tosAgreement: "আমাদের ওয়েবসাইট ব্যবহার করে, আপনি আমাদের গোপনীয়তা নীতি এবং পরিষেবার শর্তাবলীতে সম্মত হন",
    pagination: {
        page: "পেজ",
        of: ", মোট পেজ"
    },
    settings: {
        settings: "সেটিংস",
        accountSettings: "অ্যাকাউন্ট সেটিংস",
        yourBSId: "আপনার Betshop আইডি",
        changePass: "পাসওয়ার্ড পরিবর্তন",
        zoom: "জুম",
        rotate: "ঘুরান",
        errors: {
            selectImg: "একটি ছবি বেছে নিন",
            onlyJpgPng: "শুধুমাত্র JPG এবং PNG অনুমোদিত",
            unknownIsImgSelected: "অজানা ত্রুটি. আপনি কি ছবি বেছে নিয়েছেন?",
            fileTooLarge: "ছবি অনেক বড়, ছবি সামঞ্জস্য করুন বা অন্য ছবি বাছাই করুন",
            coudntUpdate: "প্রোফাইল ছবি আপডেট করা যায়নি",
            success: "সফলভাবে আপডেট হয়েছে৷"
        }
    },

    navMenus: {
        orders: "অর্ডার সমূহ",
        ordersList: "অর্ডার সমূহ",
        supports: "সহায়তা",
        settings: "সেটিংস্‌",
        logout: "লগআউট",
        home: "হোমপেজ",
        dash: "ড্যাশবোর্ড",
        payMethods: "পে মেথড",
        products: "প্রোডাক্টস",
        users: "ইউজারস",
    }
}




export default NewBnTexts;