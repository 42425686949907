import React, { useContext } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { MyContext } from '../../../App';
import { handleErrors, MyAxios } from '../../../Utils/utils';

const NewSupport = () => {
    document.title = 'New support ticket || BetShop';
    const { user, setUser, recaptchaRef, setLoading } = useContext(MyContext);

    const { register, formState: { errors }, handleSubmit } = useForm();
    const navigate = useNavigate();

    const onSubmit = async data => {
        try {
            setLoading(true);
            const captcha = await recaptchaRef.current.executeAsync();
            const r = await MyAxios.post('/supports', { jwt: user.jwt }, { captcha, support: data });
            handleErrors(r, true, setUser);
            if (r?.id) navigate('/account/supports/' + r.id);
        }
        finally { setLoading(false); }
    }

    return (
        <div>
            <h2 className="text-2xl text-center md:text-left">Support Center</h2>
            <div className='bg-white p-4 my-4 shadow rounded-lg'>
                <h3 className="mb-5 font-medium">Create new support ticket</h3>

                <form onSubmit={handleSubmit(onSubmit)}>

                    <div>
                        <span>Select Subject: </span>
                        <select {...register('subject')} className='outline-none border border-gray-400 rounded ml-3 px-2 py-1 cursor-pointer' defaultValue='other'>
                            <option value="1xbet_topup_bdt">1xBet Topup (BDT)</option>
                            <option value="1xbet_topup_usd">1xBet Topup (USD)</option>
                            <option value="1xbet_withdraw_bdt">1xBet Withdraw (BDT)</option>
                            <option value="1xbet_withdraw_usd">1xBet Withdraw (USD)</option>
                            <option value="other">Other...</option>
                        </select>
                    </div>
                    <div>
                        <p>Enter your message</p>
                        <textarea className='w-full outline-none px-4 py-2 rounded border-2 border-gray-300 focus:border-lime-500 outline-0 duration-300 h-[15vh]' {
                            ...register('text', {
                                required: { value: true, message: 'Please write a message' },
                                minLength: { value: 10, message: 'Please write a longer message' },
                                maxLength: { value: 500, message: 'Maximum 500 characters allowed' }
                            })
                        }></textarea>
                        <p className="text-xs text-red-400 mt-[-5px] mb-2">{errors?.text?.message}</p>
                        <button className='btn btn-info btn-sm block mx-auto mt-4' type='submit'>Open New Ticket</button>
                    </div>

                </form>

            </div>
        </div>
    );
};

export default NewSupport;