import axios from "axios";
import { toast } from "react-toastify";

const MyAxios = {
    get: async (url, headers = {}) => {
        return await MyAxios.getData('GET', url, headers, {});
    },
    delete: async (url, headers = {}) => {
        return await MyAxios.getData('DELETE', url, headers, {});
    },
    post: async (url, headers = {}, body = {}) => {
        return await MyAxios.getData('POST', url, headers, body);
    },
    patch: async (url, headers = {}, body = {}) => {
        return await MyAxios.getData('PATCH', url, headers, body);
    },
    getData: async (method, url, headers, body) => {
        try {
            const config = { url: "https://api.betshop.pro/v1" + url, method, headers }
            if (method !== 'GET' && method !== 'DELETE') config.data = body;
            return (await axios(config)).data;
        }
        catch (e) {
            if (e?.response?.data) return e.response.data;
        }
    }
}

const GetDbPms = async (setLoading, user, setUser, pms, setDbPms) => {
    try {
        setLoading(true);
        const r = await MyAxios.get(`/pms/all`, { jwt: user?.jwt });
        handleErrors(r, true, setUser);
        if (!r?.ok) return;
        if (r?.pms) {
            const newDbPms = [];
            r.pms.map(pm => {
                if (!pms.find(pmN => pmN.account === pm.account)) newDbPms.push(pm)
                return "";
            })
            if (newDbPms.length === 0) return toast.warn('No more payment methods found');
            setDbPms(newDbPms);
        }
    }
    catch (e) { toast.error(e.message || e); }
    finally { setLoading(false); }
}

const sleep = ms => new Promise(r => setTimeout(r, ms));

const Utils = {
    capitalizeFirstLetter: (string) => string?.charAt(0)?.toUpperCase() + string?.slice(1),
    dateFromTime: time => {
        const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
        const date = new Date(Number(time) * 1000);
        let res = '';
        res += (date.getUTCDate() < 10 ? '0' + date.getUTCDate() : date.getUTCDate()) + ' ';
        res += months[Number(date.getUTCMonth())] + ', ';
        // res += (date.getUTCFullYear().toString().slice(2)) + ' ';
        res += (date.getUTCHours() < 10 ? '0' + date.getUTCHours() : date.getUTCHours()) + ':';
        res += (date.getUTCMinutes() < 10 ? '0' + date.getUTCMinutes() : date.getUTCMinutes());
        // res += (date.getUTCSeconds() < 10 ? '0' + date.getUTCSeconds() : date.getUTCSeconds());
        return res;
    },
    orderType: type => {
        if (type === '1xbet_topup') return 'Topup';
        if (type === '1xbet_withdraw') return 'Withdraw';
    },
    LogOut: async (setUser, setLoading = false) => {
        try {
            if (setLoading) setLoading(true);
            localStorage.removeItem("jwt");
            await sleep(500);
            setUser({});
        }
        finally { setLoading(false); }
    }
}

const handleErrors = (r, alert = true, setUser) => {
    if (alert && !r?.ok) toast.error(r?.text || 'Unknown error');
    if (r?.logout) {
        localStorage.removeItem('jwt');
        setUser({});
    }
}

const copy = (text, toastMsg = false) => {
    try {
        navigator.clipboard.writeText(text);
        toast.success(toastMsg || 'Copied to clipboard');
    }
    catch (e) { toast.error('Cannot copy. Error: ' + e.message); }
}

const productNameById = id => {
    if (!id) return;
    id = id.toLowerCase();
    if (id === '1xbet_topup_bdt') return '1xBet Topup (BDT)';
    if (id === '1xbet_topup_usd') return '1xBet Topup (USD)';
    if (id === '1xbet_withdraw_bdt') return '1xBet Withdraw (BDT)';
    if (id === '1xbet_withdraw_usd') return '1xBet Withdraw (USD)';
}
const coinTypeOfPm = pm => {
    if (!pm) return '';
    pm = pm.toLowerCase();
    if (['bkash', 'nagad', 'rocket'].includes(pm)) return 'BDT';
    return '';
}

const time = (s = 0) => {
    let time = parseInt(Date.now() / 1000);
    if (s) time += s;
    return time;
}



export { MyAxios, Utils, sleep, handleErrors, GetDbPms, copy, productNameById, coinTypeOfPm, time };