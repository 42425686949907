import React from 'react';
import { useContext } from 'react';
import { IoCloseCircleSharp } from 'react-icons/io5';
import { toast } from 'react-toastify';
import { MyContext } from '../../../App';
import { handleErrors, MyAxios, Utils } from '../../../Utils/utils';

const AdminUser = ({ values: { users, setUsers, dbUser, setDbUser } }) => {
    const { user, setUser, setLoading } = useContext(MyContext);

    const changeStatus = async data => {
        try {
            setLoading(true);
            const r = await MyAxios.patch(`/users/${dbUser?.id}`, { jwt: user?.jwt }, { user: data });
            handleErrors(r, true, setUser);
            if (!r?.ok) return;
            toast.success("Success");
            const newUsers = users.map(newUser => {
                if (newUser.id === dbUser.id) newUser.role = data.role;
                return newUser;
            });
            setUsers(newUsers);
        }
        finally { setLoading(false); }
    }


    return (
        <div className='fixed -top-10 left-0 w-full h-screen backdrop-blur z-[9] flex justify-center items-center'>
            <div className='w-[450px] max-w-[90%] bg-green-300 rounded-lg shadow-lg p-5 md:p-10 admin__order__details relative duration-900'>

                <IoCloseCircleSharp className='absolute text-4xl text-red-400 cursor-pointer -top-4 -right-4' onClick={() => setDbUser(null)} />

                <div>
                    <p>Name</p>
                    <p>{dbUser?.name}</p>
                </div>
                <div>
                    <p>Email</p>
                    <p>{dbUser?.email}</p>
                </div>
                <div>
                    <p>Joined</p>
                    <p>{Utils.dateFromTime(dbUser?.created_at)}</p>
                </div>
                <div>
                    <p>Orders</p>
                    <p>{dbUser?.orders}</p>
                </div>
                <div>
                    <p>Status</p>
                    <p>
                        <select className='outline-none' onChange={e => changeStatus({ role: e.target.value })}>
                            <option value="admin" selected={dbUser?.role === 'admin'}>ADMIN</option>
                            <option value="blocked" selected={dbUser?.role === 'blocked'}>BLOCKED</option>
                            <option value="user" selected={dbUser?.role === 'user'}>USER</option>
                        </select>
                    </p>
                </div>

            </div>
        </div>
    );
};

export default AdminUser;