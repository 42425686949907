import { Navigate } from "react-router-dom";
import { toast } from "react-toastify";

const RequireAdmin = ({ values: { user, userLoading }, children }) => {
    if (userLoading) return;
    if (!user?.id) return <Navigate to='/login' replace />;
    if (user.role !== 'admin') return toast.error("Unauthorized. code: -10033");
    return children;
}
const RequireUser = ({ values: { user, userLoading }, children }) => {
    if (userLoading) return;
    if (!user?.id) return <Navigate to='/login' replace />;
    if (user.role !== 'user') return toast.error("Unauthorized. code: -10034");
    return children;
}

export { RequireAdmin, RequireUser };