import React, { useEffect, useState } from 'react';
import { useContext } from 'react';
import { IoRefresh } from 'react-icons/io5';
import { MyContext } from '../../../App';
import { handleErrors, MyAxios } from '../../../Utils/utils';
import AdminProduct from './AdminProduct';

const AdminProducts = () => {
    document.title = 'Products || BetShop';

    const { user, setUser, setLoading } = useContext(MyContext);
    const [products, setProducts] = useState([]);
    const [refresh, setRefresh] = useState(0);

    useEffect(() => {
        (async () => {
            try {
                setProducts([]);
                setLoading(true);
                const r = await MyAxios.get('/products/all', { jwt: user.jwt });
                handleErrors(r, true, setUser);
                if (!r?.ok) return;
                if (r?.products) setProducts(r.products);
            }
            finally { setLoading(false); }
        })();
    }, [setLoading, setUser, user, refresh]);

    return (
        <div>
            <h2 className="text-2xl flex gap-2 items-center justify-center md:justify-start">
                Products <IoRefresh className='text-gray-500 cursor-pointer' onClick={() => setRefresh(refresh + 1)} />
            </h2>
            <div className="products flex gap-5 mt-5 flex-wrap justify-start">
                {products.map((product, i) => <AdminProduct product={product} key={i} refresh={refresh} setRefresh={setRefresh} />)}
            </div>
        </div>
    );
};


export default AdminProducts;