import React, { useEffect, useState } from 'react';
import { IoCart } from 'react-icons/io5';
import { MdOutlineHelpOutline } from 'react-icons/md';
import HelpModal from '../../../../Utils/HelpModal';

const NewWithdraw = ({ values: { uiText, handleSubmit, onSubmit, register, errors, pms, product } }) => {
    const [showAmt, setShowAmt] = useState("0");
    const [pm, setPm] = useState(pms[0]);
    const [help, setHelp] = useState([]);
    useEffect(() => setPm(pms[0]), [pms]);

    const changeAmount = () => {
        const amount = document.getElementById('amount')?.value || 0;
        const pm_method = document.getElementById('pm_method')?.value;
        const newPm = pms?.find(pm => pm.account === pm_method);
        setPm(newPm);
        setShowAmt((amount * (newPm.rate || 0)).toFixed(2));
    }

    return (
        <form onSubmit={handleSubmit(onSubmit)}>

            {/* Help Modal */}
            {help?.[0] && <HelpModal body={help[1]} title={help[0]} setHelp={setHelp} />}

            <div className="form-control">
                <label className="label pb-0 pl-0 items-center md:justify-start gap-2">
                    <span className="label-text">{uiText.orders.new.selectPmMethod}</span>
                    <MdOutlineHelpOutline className='_help-icon' onClick={() => setHelp([uiText.orders.new.selectPmMethod, uiText.orders.new.selectPmMethodHelpP])} />
                </label>
                <select {...register("pm_method", { required: "Required" })} className='outline-none topup-box focus:border-lime-500 cursor-pointer' id="pm_method" onChange={changeAmount}>
                    {pms.map((pm, i) => <option key={i} value={pm.account}>{pm.account}</option>)}
                </select>
                <label className="label pl-1 pt-1"><span className="label-text-alt text-red-400">{errors?.pm_method?.message}</span></label>
            </div>

            <div className="form-control">
                <label className="label pb-0 pl-0 items-center md:justify-start gap-2">
                    <span className="label-text">{uiText.orders.new.wAmount} ({product.id.includes('usd') ? 'USD' : 'BDT'})</span>
                    <MdOutlineHelpOutline className='_help-icon' onClick={() => setHelp([uiText.orders.new.wAmount, uiText.orders.new.wAmountHelp])} />
                </label>
                <input type="text" className="topup-box focus:border-lime-500" id="amount" onChange={changeAmount} onKeyUp={changeAmount}
                    {...register("amount", {
                        required: "Required",
                        min: { value: pm.min, message: `Minimum ${pm.min}` },
                        max: { value: pm.max, message: `Maximum ${pm.max}` }
                    })}
                />
                <label className="label pl-1 pt-1"><span className="label-text-alt text-red-400">{errors?.amount?.message}</span></label>
            </div>

            <div className="form-control">
                <label className="label pb-0 pl-0 items-center md:justify-start gap-2">
                    <span className="label-text">{pm.account} {uiText.orders.new.wWallet}</span>
                    <MdOutlineHelpOutline className='_help-icon' onClick={() => setHelp([uiText.orders.new.wWallet, uiText.orders.new.wWalletHelp])} />
                </label>
                <input type="text" className="topup-box focus:border-lime-500"
                    {...register("wallet", {
                        required: "Required",
                        pattern: { value: /^01[0-9]{9}$/, message: 'Invalid value' }
                    })}
                />
                <label className="label pl-1 pt-1"><span className="label-text-alt text-red-400">{errors?.wallet?.message}</span></label>
            </div>

            <div className="form-control">
                <label className="label pb-0 pl-0 items-center md:justify-start gap-2">
                    <span className="label-text">{uiText.orders.new.contactEmail}</span>
                    <MdOutlineHelpOutline className='_help-icon' onClick={() => setHelp([uiText.orders.new.contactEmail, uiText.orders.new.contactEmailHelp])} />
                </label>
                <input type="text" className="topup-box focus:border-lime-500"{...register("contact_email", { required: "Required" })} placeholder='you@email.com' />
                <label className="label pl-1 pt-1"><span className="label-text-alt text-red-400">{errors?.contact_email?.message}</span></label>
            </div>

            {uiText.orders.new.showAmt.withdraw(showAmt, product, pm)}

            <button className='submit__btn w-40 mt-4 mx-auto md:mx-0'><IoCart className='text-2xl' /> {uiText.formFields.placeOrder}</button>
        </form>
    );
};

export default NewWithdraw;